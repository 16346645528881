import * as React from "react";
import { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./Report.style";
import {
  CircularProgress,
  Modal,
  Button,
  Select,
  Input,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";

import { toast, ToastContainer } from "react-toastify";

import { apiURLs } from "./config";

import _ from "lodash";
import { AddCircle } from "@mui/icons-material";

const EditableText = (props) => {
  const [value, setValue] = useState(props.value);
  const [editing, setEditing] = useState(props.edit || false);
  const inputRef = useRef(null);
  const { width = "80%" } = props;
  const { textStyle = styles.textStyle } = props;
  const { inputType = "text" } = props;
  const { options = [] } = props;
  const { text = false } = props;
  React.useEffect(() => {
    // console.log('changing value...', props.value, value);
    setValue(props.value);
  }, [props.value]);

  return (
    <div style={styles.editInputWrapper}>
      {editing ? (
        <div style={styles.editRow}>
          {options.length > 0 ? (
            !text ? (
              <select
                onChange={(e) => {
                  // console.log('chaning value')
                  setValue(e.target.value);
                }}
                style={styles.editInput(width)}
                ref={inputRef}
                value={value}
                onBlur={(e) => {
                  console.log("focus out...");
                  setEditing(false);
                  props.onChange(value);
                }}
              >
                {options.map((option, index) => {
                  return <option key={index}>{option}</option>;
                })}
              </select>
            ) : (
              <>
                <select
                  onChange={(e) => {
                    // console.log('chaning value')
                    setValue(e.target.value);
                  }}
                  style={styles.editInput(width)}
                  ref={inputRef}
                  value={value}
                  onBlur={(e) => {
                    console.log("focus out...");
                    setEditing(false);
                    props.onChange(value);
                  }}
                >
                  {options.map((option, index) => {
                    return <option key={index}>{option}</option>;
                  })}
                </select>
                <input
                  style={styles.editInput(width)}
                  ref={inputRef}
                  type={inputType}
                  value={value}
                  onChange={(e) => {
                    // console.log('chaning value')
                    setValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    console.log("focus out...");
                    setEditing(false);
                    props.onChange(value);
                  }}
                />
              </>
            )
          ) : (
            <input
              style={styles.editInput(width)}
              ref={inputRef}
              type={inputType}
              value={value}
              onChange={(e) => {
                // console.log('chaning value')
                setValue(e.target.value);
              }}
              onBlur={(e) => {
                console.log("focus out...");
                setEditing(false);
                props.onChange(value);
              }}
            />
          )}

          <SaveIcon
            onClick={() => {
              setEditing(false);
              props.onChange(value);
            }}
          />
        </div>
      ) : (
        <div style={styles.editRow}>
          <span style={textStyle}>{value}</span>
          <EditIcon style={styles.editIcon} onClick={() => setEditing(true)} />
        </div>
      )}
    </div>
  );
};

export default function Report(props) {
  const { jobId } = useParams();
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stonesData, setStonesData] = useState(null);
  const [originalStonesData, setOriginalStonesData] = useState(null);
  const [stoneIndex, setStoneIndex] = useState(0);
  const [patientData, setPatientData] = useState(null);
  const [kidneyData, setKidneyData] = useState(null);
  const [impressions, setImpressions] = useState([]);
  const [stoneModalOpen, setStoneModalOpen] = useState(false);
  const [newStoneData, setNewStoneData] = useState({});
  const [newImpression, setNewImpression] = useState("");
  const [otherOrgans, setOtherOrgans] = useState({});

  // const [ baseURL, setBaseURL ] = useState('');
  const [baseURLAPI, setBaseURLAPI] = useState("");
  const [center, setCenter] = useState("");
  const [admin, setAdmin] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [config, setConfig] = useState(props.config);

  const [saving, setSaving] = useState(false);

  const [allowEditImpressions, setAllowEditImpressions] = useState(false);

  const token = admin
    ? localStorage.getItem(`token-${center}`)
    : localStorage.getItem("token");

  React.useEffect(() => {
    console.log("getting center and admin");
    // get center from local storage
    const center = localStorage.getItem("center");
    const admin = localStorage.getItem("admin");

    setCenter(center);
    setAdmin(admin === "true");

    console.log(center, admin, "center and admin");

    // setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
  }, []);

  React.useEffect(() => {
    if (baseURLAPI === "") return;
    if (saving) return;
    // get configs
    axios
      .get(`${apiURLs[center]}/configs?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("configs", res.data);
        let configs = res.data.configs;
        setConfigs(configs);
        setConfig(configs[configs.length - 1]);
        console.log("config", res.data.configs[res.data.configs.length - 1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (baseURLAPI === "") return;
    if (saving) return;
    // get configs
    axios
      .get(`${apiURLs[center]}/configs?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("configs", res.data);
        let configs = res.data.configs;
        setConfigs(configs);
        setConfig(configs[configs.length - 1]);
        console.log("config", res.data.configs[res.data.configs.length - 1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [baseURLAPI, saving]);

  React.useEffect(() => {
    if (baseURLAPI === "") return;
    console.log("getting report data...");
    axios
      .get(`${baseURLAPI}/json?job_id=${jobId}&config=${config}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("stones", res);
        let stones = {};
        for (let i = 0; i < res.data.stones.length; i++) {
          stones[`stone_${i + 1}`] = res.data.stones[i];
          stones[`stone_${i + 1}`].staghorn = stones[`stone_${i + 1}`].staghorn
            ? 1
            : 0;
        }
        console.log("stones", stones);
        setOriginalStonesData(stones);
        setStonesData(stones);
        setPatientData(res.data.patient);
        setKidneyData(res.data.kidney);
        // setImpressions('No Impression');
        setImpressions(res.data.impressions);

        console.log("stones", stones);
        console.log("patient", res.data.patient);
        console.log("kidney", res.data.kidney);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [baseURLAPI, config]);

  const onDownloadReport = () => {
    axios({
      method: "GET",
      url: `${baseURLAPI}/pdf?job_id=${jobId}&config_name=${config}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "arraybuffer",
    })
      .then((res) => {
        // download file
        console.log("res", res);
        let blob = new Blob([res.data], { type: "application/pdf" });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = "report.pdf";
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSaveReport = () => {
    setSaving(true);
    console.log("stonesData", stonesData);
    axios({
      method: "POST",
      url: `${baseURLAPI}/pdf?job_id=${jobId}&config_name=${config}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        patient: patientData,
        kidney: kidneyData,
        stones: stonesData,
        impressions: impressions,
        other_organs: otherOrgans,
        allow_edit_impressions: allowEditImpressions,
        deep: _.isEqual(stonesData, originalStonesData) ? false : true,
      },
    })
      .then((res) => {
        // download file
        console.log("res", res);
        // toast.success('Report saved successfully');
        // wait for task to finish
        let interval = setInterval(() => {
          // every 1 second
          axios
            .get(`${baseURLAPI}/task_status/${res.data.task_id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              // console.log('task', res);
              if (res.data.status === "SUCCESS") {
                clearInterval(interval);
                setSaving(false);
                toast.success("Report saved successfully");
              }
              if (props.onConfigSaved) {
                console.log("calling onConfigSaved");
                props.onConfigSaved();
              }
            })
            .catch((err) => {
              console.log(err);
              setSaving(false);
              toast.error("Error saving report");
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
        toast.error("Error saving report");
      });
  };

  console.log("new impression", impressions);
  console.log("stone data", stonesData);
  return (
    <>
      {!loading ? (
        <div style={styles.root}>
          <div style={styles.pageHeader}>
            <div>
              Config:
              <Select
                value={config}
                style={styles.configsSelect}
                onChange={(e) => {
                  setConfig(e.target.value);
                }}
              >
                {configs.map((config, i) => {
                  return (
                    <MenuItem key={i} value={config}>
                      {config}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <Button onClick={onSaveReport} style={styles.saveButton}>
              Save
              <DownloadIcon style={styles.downloadIcon} />
            </Button>
            <Button onClick={onDownloadReport} style={styles.downloadButton}>
              Download
              <DownloadIcon style={styles.downloadIcon} />
            </Button>
            {saving ? <CircularProgress style={styles.progress} /> : null}
          </div>
          <div style={{ width: "100%" }}>
            <div style={styles.header}>
              <p
                style={{ height: "10px", fontWeight: "800", fontSize: "18pt" }}
              >
                Study: CT KUB PLAIN
              </p>
              <div style={styles.header2}>
                <div style={styles.headerLeft}>
                  <span style={styles.patientText}>
                    Bill ID: &nbsp; &nbsp; &nbsp; {patientData.patient_id}
                  </span>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={styles.patientText}>
                      Name:{" "}
                      <EditableText
                        width={"200px"}
                        value={patientData.patient_name}
                        onChange={(value) => {
                          setPatientData({
                            ...patientData,
                            patient_name: value,
                          });
                        }}
                      />
                    </div>
                    <div style={styles.patientText}>
                      {" "}
                      Age:{" "}
                      <EditableText
                        width={"200px"}
                        value={patientData.age}
                        onChange={(value) => {
                          setPatientData({ ...patientData, age: value });
                        }}
                      />
                    </div>
                    <div style={styles.patientText}>
                      {" "}
                      Sex:{" "}
                      <EditableText
                        value={patientData.sex}
                        onChange={(value) => {
                          setPatientData({ ...patientData, sex: value });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={styles.headerRight}>
                  <span style={styles.patientText}>
                    Study Date: &nbsp; &nbsp; {patientData.study_date}
                  </span>
                  <span style={styles.patientText}>
                    Referring Doctor: &nbsp; &nbsp;{" "}
                    {patientData.referring_doctor}
                  </span>
                </div>
              </div>
            </div>
            <div style={styles.content}>
              <div style={styles.headerTextWrapper}>
                <h1 style={styles.headerText}>Findings:</h1>
              </div>
              <table style={styles.kidneyTable}>
                <tr>
                  <th style={styles.tableHeaderItem}>Side</th>
                  <th style={styles.tableHeaderItem}>Size (in mm)</th>
                  <th style={styles.tableHeaderItem}>HUN/HN</th>
                  <th style={styles.tableHeaderItem}>Calculus</th>
                  <th style={styles.tableHeaderItem}>PFS</th>
                  {"cyst" in kidneyData ? (
                    <th style={styles.tableHeaderItem}>Cyst</th>
                  ) : null}
                  <th style={styles.tableHeaderItem}>Stent</th>
                </tr>
                <tr>
                  <td style={{ ...styles.tableItem, ...styles.textStyle }}>
                    Right Kidney
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      value={kidneyData.right_kidney_size}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          right_kidney_size: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      options={[
                        "No",
                        "Mild HydroNephrosis",
                        "Moderate HydroNephrosis",
                        "Severe HydroNephrosis",
                        "Mild HydroUreteroNephrosis",
                        "Moderate HydroUreteroNephrosis",
                        "Severe HydroUreteroNephrosis",
                      ]}
                      value={kidneyData.right_kidney_hun_hn}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          right_kidney_hun_hn: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      inputType="number"
                      value={kidneyData.right_kidney_calculus}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          right_kidney_calculus: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      options={["YES", "No"]}
                      value={kidneyData.right_pfs}
                      onChange={(value) => {
                        setKidneyData({ ...kidneyData, right_pfs: value });
                      }}
                    />
                  </td>
                  {"cyst" in kidneyData ? (
                    <td style={styles.tableItem}>
                      <EditableText
                        value={kidneyData.cyst.right && "YES"}
                        onChange={(value) => {
                          let cyst = kidneyData.cyst;
                          if (
                            value.startsWith("N") ||
                            value === "-" ||
                            value === ""
                          ) {
                            cyst.right = false;
                          } else {
                            cyst.right = true;
                          }
                          setKidneyData({
                            ...kidneyData,
                            right_pfs: value,
                            cyst: cyst,
                          });
                          console.log("kidneyData", kidneyData);
                        }}
                      />
                    </td>
                  ) : null}
                  <td style={styles.tableItem}>
                    <EditableText
                      options={["YES", "No"]}
                      value={kidneyData.right_ureter_stent}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          right_ureter_stent: value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tableItem, ...styles.textStyle }}>
                    Left Kidney
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      value={kidneyData.left_kidney_size}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          left_kidney_size: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      options={[
                        "No",
                        "Mild HydroNephrosis",
                        "Moderate HydroNephrosis",
                        "Severe HydroNephrosis",
                        "Mild HydroUreteroNephrosis",
                        "Moderate HydroUreteroNephrosis",
                        "Severe HydroUreteroNephrosis",
                      ]}
                      value={kidneyData.left_kidney_hun_hn}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          left_kidney_hun_hn: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      inputType="number"
                      value={kidneyData.left_kidney_calculus}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          left_kidney_calculus: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>
                    <EditableText
                      options={["YES", "No"]}
                      value={kidneyData.left_pfs}
                      onChange={(value) => {
                        setKidneyData({ ...kidneyData, left_pfs: value });
                      }}
                    />
                  </td>
                  {"cyst" in kidneyData ? (
                    <td style={styles.tableItem}>
                      <EditableText
                        value={kidneyData.cyst.left && "YES"}
                        onChange={(value) => {
                          let cyst = kidneyData.cyst;
                          if (
                            value.startsWith("N") ||
                            value === "-" ||
                            value === ""
                          ) {
                            cyst.left = false;
                          } else {
                            cyst.left = true;
                          }
                          setKidneyData({
                            ...kidneyData,
                            right_pfs: value,
                            cyst: cyst,
                          });
                          console.log("kidneyData", kidneyData);
                        }}
                      />
                    </td>
                  ) : null}
                  <td style={styles.tableItem}>
                    <EditableText
                      options={["YES", "No"]}
                      value={kidneyData.left_ureter_stent}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          left_ureter_stent: value,
                        });
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ ...styles.tableItem, ...styles.textStyle }}>
                    Bladder
                  </td>
                  <td style={styles.tableItem}>-</td>
                  <td style={styles.tableItem}>-</td>
                  <td style={styles.tableItem}>
                    <EditableText
                      value={kidneyData.bladder_calculus}
                      onChange={(value) => {
                        setKidneyData({
                          ...kidneyData,
                          bladder_calculus: value,
                        });
                      }}
                    />
                  </td>
                  <td style={styles.tableItem}>-</td>
                  <td style={styles.tableItem}>-</td>
                </tr>
              </table>
              <div style={styles.headerTextWrapper}>
                <h1 style={styles.headerText}>Calculus:</h1>
              </div>
              <table style={styles.calculusTable}>
                <tr>
                  <th style={styles.tableHeaderItem}>ID</th>
                  <th style={styles.tableHeaderItem}>Side</th>
                  <th style={styles.tableHeaderItem}>Num Stones</th>
                  <th style={styles.tableHeaderItem}>Staghorn</th>
                  <th style={styles.tableHeaderItem}>Size (in mm)</th>
                  <th style={styles.tableHeaderItem}>HU Value</th>
                  <th style={styles.tableHeaderItem}>Location</th>
                  <th style={styles.tableHeaderItem}>Distance</th>
                  {admin ? (
                    <th style={styles.tableHeaderItem}>Actions</th>
                  ) : null}
                </tr>
                {stonesData &&
                  Object.keys(stonesData).map((stone, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{ ...styles.tableItem, ...styles.textStyle }}
                        >
                          {index + 1}
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            text={true}
                            options={[
                              "RightKidney",
                              "RightKidney (stent)",
                              "RightUreter",
                              "RightUreter (upper)",
                              "RightUreter (middle)",
                              "RightUreter (lower)",
                              "RightUreter (upper, stent)",
                              "RightUreter (middle, stent)",
                              "RightUreter (lower, stent)",
                              "LeftKidney",
                              "LeftKidney (stent)",
                              "LeftUreter",
                              "LeftUreter (upper)",
                              "LeftUreter (middle)",
                              "LeftUreter (lower)",
                              "LeftUreter (upper, stent)",
                              "LeftUreter (middle, stent)",
                              "LeftUreter (lower, stent)",
                              "Bladder",
                            ]}
                            value={stonesData[stone].side}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: { ...stonesData[stone], side: value },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            value={stonesData[stone].num_stones}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: {
                                  ...stonesData[stone],
                                  num_stones: value,
                                },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            value={stonesData[stone].staghorn}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: {
                                  ...stonesData[stone],
                                  staghorn: parseInt(value),
                                },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            value={stonesData[stone].size}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: { ...stonesData[stone], size: value },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            value={stonesData[stone].hu_value}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: {
                                  ...stonesData[stone],
                                  hu_value: value,
                                },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            options={[
                              "upper calyx (A)",
                              "upper calyx (P)",
                              "middle calyx (A)",
                              "middle calyx (P)",
                              "lower calyx (A)",
                              "lower calyx (P)",
                              "puj",
                              "UVJ",
                              "Bladder",
                              "Renal pelvis",
                            ]}
                            text={true}
                            value={stonesData[stone].location}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: {
                                  ...stonesData[stone],
                                  location: value,
                                },
                              });
                            }}
                          />
                        </td>
                        <td style={styles.tableItem}>
                          <EditableText
                            value={stonesData[stone].distance}
                            onChange={(value) => {
                              setStonesData({
                                ...stonesData,
                                [stone]: {
                                  ...stonesData[stone],
                                  distance: value,
                                },
                              });
                            }}
                          />
                        </td>
                        {admin || center == "cect" ? (
                          <td style={styles.tableItem}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <DeleteIcon
                                style={styles.deleteIcon}
                                onClick={() => {
                                  let newStonesData = {};
                                  let stoneKeys = Object.keys(stonesData);
                                  // console.log('stonesData', stonesData);
                                  for (let i = 0; i < stoneKeys.length; i++) {
                                    if (i === index) continue;
                                    newStonesData[stoneKeys[i]] =
                                      stonesData[stoneKeys[i]];
                                  }
                                  // console.log('newStonesData', newStonesData);
                                  setStonesData(newStonesData);
                                }}
                              />
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
              </table>
              {/* Add new stone row */}
              {/* <div style={styles.addStoneRow}>
                            <Button onClick={() => {
                                // Open modal and let user insert
                                setStoneModalOpen(true);

                            }
                            }>Add Stone</Button>    
                        </div> */}
              <div style={styles.headerTextWrapper}>
                <h1 style={styles.abdomenOrganHeader}>Impression:</h1>
              </div>
              {admin ? (
                <div style={styles.addStoneRow}>
                  <Switch
                    checked={allowEditImpressions}
                    onChange={(value) => {
                      setAllowEditImpressions(!allowEditImpressions);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setStoneModalOpen(true);
                    }}
                  >
                    <AddCircle sx={{ color: "green" }} />
                  </IconButton>
                </div>
              ) : null}
              <ol style={styles.impressionsList}>
                {impressions.map((impression, index) => {
                  return (
                    <li>
                      <div style={styles.impressionListItem}>
                        {admin && allowEditImpressions ? (
                          <EditableText
                            key={index}
                            width={"90%"}
                            edit={true}
                            textStyle={styles.impressionText}
                            value={impression}
                            onChange={(value) => {
                              let newImpressions = impressions.slice(0);
                              newImpressions[index] = value;
                              setImpressions(newImpressions);
                            }}
                          />
                        ) : null}
                        {admin && allowEditImpressions ? (
                          <DeleteIcon
                            style={styles.deleteIcon}
                            onClick={() => {
                              console.log("impression delete!!!");
                              let newImpressions = [];
                              for (let i = 0; i < impressions.length; i++) {
                                if (i === index) continue;
                                newImpressions.push(impressions[i]);
                              }
                              setImpressions(newImpressions);
                            }}
                          />
                        ) : null}
                        {!allowEditImpressions ? (
                          <p style={styles.impressionText}>{impression}</p>
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ol>
              {/* <div style={styles.addStoneRow}>
                            <Button onClick={() => {
                                // Open modal and let user insert
                                // setStoneModalOpen(true);
                                setImpressions(oldImpressions => {
                                    return [
                                        ...oldImpressions,
                                        '',
                                    ]
                                });

                            }
                            }>Add Impression</Button>    
                        </div>     */}
            </div>
          </div>
        </div>
      ) : (
        <CircularProgress style={{ margin: "20px" }} />
      )}
      <Modal
        open={stoneModalOpen}
        onClose={() => {
          setStoneModalOpen(false);
        }}
      >
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <h1 style={styles.modalHeaderText}>Add Impression</h1>
            <TextField
              variant="outlined"
              onChange={(e) => {
                setNewImpression(e.target.value);
              }}
            />
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                let newImpressions = impressions.slice(0);
                newImpressions.push(newImpression);
                if (newImpression) {
                  setImpressions(newImpressions);
                }
                setNewImpression("");
                setStoneModalOpen(false);
              }}
            >
              Add
            </Button>
            <IconButton
              onClick={() => {
                setStoneModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={styles.modalFooter}></div>
        </div>
      </Modal>
    </>
  );
}
