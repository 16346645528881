import React, { useState } from "react";
import Spline from "@splinetool/react-spline";
import "./ModelViewer.css";

export default function Scene() {
  const [isLoading, setIsLoading] = useState(true);

  const handleSceneLoaded = () => {
    console.log("loaded");
    setIsLoading(false);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "#000",
      }}
    >
      {isLoading ? (
        // Show the loader while the spline scene is loading
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            background: "#000",
          }}
        >
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <Spline
            style={{ opacity: "0", position: "absolute" }}
            scene="https://prod.spline.design/kLEd8gdy2vhGhiOn/scene.splinecode"
            onLoad={handleSceneLoaded}
          />
        </div>
      ) : (
        <Spline scene="https://prod.spline.design/kLEd8gdy2vhGhiOn/scene.splinecode" />
      )}
    </div>
  );
}
