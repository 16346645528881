import * as React from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

import { Buffer } from "buffer";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { CircularProgress, Input, LinearProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { styled, keyframes } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Popper from "@mui/material/Popper";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import ListIcon from "@mui/icons-material/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  TbZoomPan,
  TbZoomCode,
  TbCrosshair,
  TbZoomReset,
  TbRulerMeasure,
  TbEraser,
  TbEraserOff,
  TbPencilOff,
  TbPencil,
  TbPolygon,
  TbPolygonOff,
  TbPlayerPlay,
  TbPlayerPause,
  TbKeyframes,
  TbCrop,
  TbReportMedical,
  TbFileTypePdf,
  TbDatabasePlus,
  TbPhotoCog,
  TbMessage,
} from "react-icons/tb";

import FullScreenIcon from "@mui/icons-material/Fullscreen";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import DescriptionIcon from '@mui/icons-material/Description';


import { inferURLs, apiURLs } from "./config";
import styles from "./View.style";
import "./View.css";

// import io from "socket.io-client";

import "@kitware/vtk.js/Rendering/Profiles/Geometry";
import vtkFullScreenRenderWindow from "./FullScreenRenderWindow";

import vtkRenderWindowInteractor from "@kitware/vtk.js/Rendering/Core/RenderWindowInteractor";
// import vtkGenericRenderWindow from '@kitware/vtk.js/Rendering/Misc/GenericRenderWindow.js';
import vtkActor from "@kitware/vtk.js/Rendering/Core/Actor";
import vtkMapper from "@kitware/vtk.js/Rendering/Core/Mapper";
import vtkOBJReader from "@kitware/vtk.js/IO/Misc/OBJReader";
import vtkMTLReader from "@kitware/vtk.js/IO/Misc/MTLReader";
import vtkPicker from "@kitware/vtk.js/Rendering/Core/Picker";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { ColorPicker } from "material-ui-color";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { stoneColors, allColors } from "./config";

import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";

import Report from "./Report";

import { Document, Page, pdfjs } from "react-pdf";

import _ from "lodash";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// import workerScript from './parser-worker';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const bounce = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;
const LoadingDash = styled("div")({
  display: "flex",
  justifyContent: "center",
  "& > div": {
    width: 8,
    height: 8,
    margin: "3px 2px",
    borderRadius: "50%",
    backgroundColor: "#a3a1a1",
    opacity: 1,
    animation: `${bounce} 0.6s infinite alternate`,
    "&:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-child(3)": {
      animationDelay: "0.4s",
    },
  },
});
const LoadingComponent = () => (
  <LoadingDash>
    <div></div>
    <div></div>
    <div></div>
  </LoadingDash>
);

const PrettoSlider = styled(Slider)({
  color: "#52af77",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#52af77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const colorArrayToHexString = (colorArray) => {
  return (
    "#" +
    colorArray
      .map((x) => {
        return ("0" + x.toString(16)).slice(-2);
      })
      .join("")
  );
};

const VisibilityEye = (props) => {
  const [visible, setVisible] = React.useState(props.visible);

  React.useEffect(() => {
    setVisible(props.visible);
  }, [props.visible]);
  if (visible) {
    return (
      <VisibilityIcon
        key={props.key}
        onClick={() => {
          setVisible(false);
          props.onVisibilityChange(props.name, false);
        }}
      />
    );
  } else {
    return (
      <VisibilityOffIcon
        key={props.key}
        onClick={() => {
          setVisible(true);
          props.onVisibilityChange(props.name, true);
        }}
      />
    );
  }
};

const FullScreenButton = (props) => {
  return (
    <div
      style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}
    >
      <FullScreenIcon
        style={{
          top: "10px",
          right: "10px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => {
          props.onClick();
        }}
      />
    </div>
  );
};
const Feedback = (props) => {
  return (
    <div style={styles.feedback}>
      <p style={{ fontSize: "12pt", fontWeight: "600" }}>
        {props.index}. {props.feedback}
      </p>
      <div style={styles.feedbackFooter}>
        <p style={{ fontSize: "10pt" }}>{props.feedbackBy}</p>
        <p style={{ fontSize: "10pt" }}>
          {new Date(props.feedbackDate).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            hour12: false,
          })}
        </p>
      </div>
    </div>
  );
};
const EditableText = (props) => {
  const [value, setValue] = React.useState(props.value);
  const [editing, setEditing] = React.useState(props.edit || false);
  const inputRef = React.useRef(null);
  const { width = "80%" } = props;
  const { textStyle = {} } = props;
  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div style={styles.editInputWrapper}>
      {editing ? (
        <div style={styles.editRow}>
          <input
            style={styles.editInput(width)}
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={(e) => {
              setEditing(false);
              props.onChange(value);
            }}
          />

          <SaveIcon
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setEditing(false);
              props.onChange(value);
            }}
          />
        </div>
      ) : (
        <div style={styles.editRow}>
          <span style={textStyle}>{value}</span>
          <EditIcon style={styles.editIcon} onClick={() => setEditing(true)} />
        </div>
      )}
    </div>
  );
};

export default function View(props) {
  const { jobId } = useParams();
  const [drawingbbox, setDrawingbbox] = React.useState(false);
  const [zoom, setZoom] = React.useState({
    axial: 1,
    coronal: 1,
    sagittal: 1,
  });
  const [pan, setPan] = React.useState({
    axial: { x: 0, y: 0 },
    coronal: { x: 0, y: 0 },
    sagittal: { x: 0, y: 0 },
  });

  const isPanningRef = React.useRef({
    axial: false,
    coronal: false,
    sagittal: false,
  });
  const containerRefs = React.useRef({
    axial: React.createRef(),
    coronal: React.createRef(),
    sagittal: React.createRef(),
  });
  const minFovDistance = 100;
  const [xvalue, setxValue] = React.useState([10, 500]);
  const [yvalue, setyValue] = React.useState([10, 500]);
  const [zvalue, setzValue] = React.useState([10, 500]);
  const [onVerification, setOnVerification] = React.useState(false);
  const [qcuser, setQcuser] = React.useState("");
  const [dicomMetadata, setDicomMetadata] = React.useState({});
  const [measureLength, setMeasureLength] = React.useState(false);
  const [linemeasure, setLinemeasure] = React.useState({});
  const [DrawingLine, setDrawingLine] = React.useState(false);
  const [totalLength, setTotalLength] = React.useState(0);

  const [activeTool, setActiveTool] = React.useState(null);
  const [cineStep, setCineStep] = React.useState(1);
  const [cineFPS, setCineFPS] = React.useState(1);
  const [caseinfo, setCaseinfo] = React.useState({});
  const [startCine, setStartCine] = React.useState({
    axial: false,
    coronal: false,
    sagittal: false,
  });
  const [pauseCine, setPauseCine] = React.useState({
    axial: true,
    coronal: true,
    sagittal: true,
  });

  const [PlaneValue, setPlaneValue] = React.useState("axial");
  const [isMouseDown, setIsMouseDown] = React.useState(false);

  const [stoneNav, setstoneNav] = React.useState(true);

  const handlePlaneChange = (event, newValue) => {
    setPlaneValue(newValue);
    handleFullscreenClick(newValue);
  };

  const [fetched, setFetched] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState({
    axial: 0,
    coronal: 0,
    sagittal: 0,
  });
  const [imageData3D, setImageData3D] = React.useState(null);

  const [imagesFetchedList, setImagesFetchedList] = React.useState({});
  const [plane, setPlane] = React.useState("axial");
  const [unloadableMessage, setUnloadableMessage] = React.useState(false);
  const [metricsModalOpen, setMetricsModalOpen] = React.useState(false);
  const [modelDownload, setModelDownload] = React.useState(["kub"]);
  const [labelsImages, setLabelsImages] = React.useState({});
  const [labelsLoaded, setLabelsLoaded] = React.useState(false);
  const [selectedViewModel, setSelectedViewModel] = React.useState(["kub"]);
  const [labelsColorsVals, setLabelsColorsVals] = React.useState({});
  const [labelToVals, setLabelToVals] = React.useState({});
  const [switchLabelsState, setSwitchLabelsState] = React.useState({});
  const [labelsColors, setLabelsColors] = React.useState({});
  const [labelsOpacity, setLabelsOpacity] = React.useState(0.65);
  const [windowVals, setWindowVals] = React.useState([40, 400]);
  const [numLoaded, setNumLoaded] = React.useState(0);
  const [imgSize, setImgSize] = React.useState([0, 0]);
  const [numImages, setNumImages] = React.useState(0);
  const [models, setModels] = React.useState([]);
  const [stones, setStones] = React.useState([]);
  const [stone, setStone] = React.useState(null);
  const [probeOn, setProbeOn] = React.useState(false);
  const [probeValue, setProbeValue] = React.useState(0);
  const [panOn, setPanOn] = React.useState(false);
  const [addStone, setAddStone] = React.useState(false);
  const [zoomv, setZoomv] = React.useState(false);
  const [crossHairOn, setCrossHairOn] = React.useState(false);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchor, setPopperAnchor] = React.useState(null);
  const [stoneModalOpen, setStoneModalOpen] = React.useState(false);
  const [stoneIndex, setStoneIndex] = React.useState(0);
  const [probePosition, setProbePosition] = React.useState("");

  const [filteredStones, setFilteredStones] = React.useState([]);
  const [showFilteredStones, setShowFilteredStones] = React.useState(false);

  const [configs, setConfigs] = React.useState({});
  const [config, setConfig] = React.useState("");
  const [savedConfig, setSavedConfig] = React.useState(false);
  const [reload, setReload] = React.useState(0);
  const [optionsOpen, setOptionsOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saveStatus, setSaveStatus] = React.useState("");

  const [addStoneError, setAddStoneError] = React.useState(false);

  // const [ loaded, setLoaded ] = React.useState(false);
  const [loadedOnce, setLoadedOnce] = React.useState(false);
  const vtkContainerRef = React.useRef(null);
  const context = React.useRef(null);
  const [scene, setScene] = React.useState([]);
  const [model, setModel] = React.useState(null);
  const [models3D, setModels3D] = React.useState([]);
  const [fullScreenRenderer, setFullScreenRenderer] = React.useState(null);
  const [objReaders, setObjReaders] = React.useState({});
  const [mtlReaders, setMtlReaders] = React.useState({});
  const [windowInteractor, setWindowInteractor] = React.useState(null);
  const [modelOptions, setModelOptions] = React.useState({});
  const [stoneColours, setStoneColours] = React.useState({});
  const [eventsSet, setEventsSet] = React.useState(false);
  const [patientName, setPatientName] = React.useState("");
  const [stonesData, setStonesData] = React.useState({});
  const [modalOpen, setModalOpen] = React.useState(false);
  const [stoneSelected, setStoneSelected] = React.useState(null);
  const [optionsDrawerOpen, setOptionsDrawerOpen] = React.useState(false);
  const [popperPosition, setPopperPosition] = React.useState([0, 0]);
  const [addingStone, setAddingStone] = React.useState(false);
  const [width, setWidth] = React.useState({
    axial: 400,
    coronal: 400,
    sagittal: 400,
    "3D": 400,
  });
  const [height, setHeight] = React.useState({
    axial: 400,
    coronal: 400,
    sagittal: 400,
    "3D": 400,
  });

  const [fullscreen, setFullscreen] = React.useState(false);

  const [showMoreOptions, setShowMoreOptions] = React.useState(false);

  const [addStoneMethod, setAddStoneMethod] = React.useState("Point");

  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const [reportConfigs, setReportConfigs] = React.useState([]);
  const [configModalOpen, setConfigModalOpen] = React.useState(false);
  const [FovModalOpen, setFovModalOpen] = React.useState(false);
  const [reportMode, setReportMode] = React.useState("view");
  const [MaskModalOpen, setMaskModalOpen] = React.useState(false);
  const [reportConfig, setReportConfig] = React.useState("original");

  const [reportLoading, setReportLoading] = React.useState(false);

  const [pdfData, setPdfData] = React.useState(null);
  const [pdfFile, setPdfFile] = React.useState(null);

  const [pdfNumPages, setPdfNumPages] = React.useState(1);
  const [pdfIndex, setPdfIndex] = React.useState(0);

  const [erpVolumes, setErpVolumes] = React.useState({});
  const [smtvalue, setsmtvalue] = React.useState({});

  const [numVisitors, setNumVisitors] = React.useState(0);

  const [cropped, setCropped] = React.useState([]);

  const [showCropped, setShowCropped] = React.useState(
    localStorage.getItem("showCropped") === null
      ? true
      : localStorage.getItem("showCropped") === "true"
  );

  const [editMaskOptions, setEditMaskOptions] = React.useState({
    erase: false,
    draw: false,
    drawPolygon: false,
    dragging: false,
    selectedLabelVal: 0,
    drawSize: 50,
  });
  const [polygonPoints, setPolygonPoints] = React.useState([]);

  const [edited, setEdited] = React.useState(0);

  // const width = size;

  let canvasRef = null;

  const [baseURL, setBaseURL] = React.useState("");
  const [baseURLAPI, setBaseURLAPI] = React.useState("");
  const [center, setCenter] = React.useState("");
  const [admin, setAdmin] = React.useState(false);
  const [mode, setMode] = React.useState();

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const isLandscape = dimensions.width > dimensions.height;

  const [labels, setLabels] = React.useState({});

  const [bboxPoints, setBboxPoints] = React.useState({});

  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);
  const [newFeedback, setNewFeedback] = React.useState("");
  const [feedbacks, setFeedbacks] = React.useState([]);
  const feedbackValues = [
    "Cyst",
    "Mass",
    "Bladder wall thickening",
    "Single kidney",
    "Horseshoe kidney",
    "Ectopic kidney",
    "Extra renal pelvis",
    "Severe HUN ",
    "Prostate calculus",
    "Urethral calculus",
    "Peripheral calculus",
    "Renal artery calcifications",
    "Phleboliths",
    "Cystic calcification",
    "Malrotated kidney",
    "Duplex Ureter",
    "Diverticulum",
    "Staghorn calculus",
    "FN in kidney",
    "FN in ureter ",
    "FN in bladder",
    "FP in kidney",
    "FP in ureter",
    "FP in bladder",
    "More than 2 kidneys",
    "No bladder",
    "bowel artifact",
    "beam hardening artifact/streak artifact",
    "separate calculi clubbed as 1 calculus due to region growing",
    "poor kub mask",
    "incorrect location",
    "atrophic kidney",
    "bladder mass",
    "air foci",
    "cortical irregularity",
  ];
  const [feedbackSelected, setfeedbackSelected] = React.useState([]);
  const [preprocessedMask, setpreprocessedMask] = React.useState(null);
  const [stoneMask, setstoneMask] = React.useState(null);

  const [logText, setLogText] = React.useState("");
  const [logModalOpen, setLogModalOpen] = React.useState(false);

  const token = admin
    ? localStorage.getItem(`token-${center}`)
    : localStorage.getItem("token");

  React.useEffect(() => {
    // get center from local storage
    const center = localStorage.getItem("center");
    const admin = localStorage.getItem("admin");
    // check if mode is set in local storage
    const mode = localStorage.getItem("mode") || "dark";

    setCenter(center);
    setAdmin(admin === "true");
    setMode(mode);

    setBaseURL(inferURLs[center]);
    setBaseURLAPI(apiURLs[center]);
    if (dimensions.width < 450) {
      setOptionsOpen(false);
      setWidth({
        axial: dimensions.width * 0.8,
        coronal: dimensions.width * 0.8,
        sagittal: dimensions.width * 0.8,
        "3D": dimensions.width * 0.8,
      });
      setHeight({
        axial: dimensions.width * 0.8,
        coronal: dimensions.width * 0.8,
        sagittal: dimensions.width * 0.8,
        "3D": dimensions.width * 0.8,
      });
    }
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    const url = `${inferURLs[center]}/jobs?text_filter=${jobId}&show_all=true`;
    async function fetchCase() {
      try {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            // console.log("res", res.data);
            setCaseinfo(res.data.jobs[0]);
            if (res.data.jobs[0]["onVerification"]) {
              setOnVerification(true);
              setQcuser(res.data.jobs[0]["qc_user"]);
            } else {
              setOnVerification(false);
              setQcuser("");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (e) {
        console.log("error fetching job details");
      }
    }
    const intervalforfetch = setInterval(() => {
      fetchCase();
    }, 1000 * 5);
    return () => clearInterval(intervalforfetch);
  }, []);

  React.useEffect(() => {
    if (baseURLAPI !== "") {
      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");
      let url;
      if (showCropped) {
        url = `${baseURLAPI}/view_metadata_cropped?job_id=${jobId}`;
      } else {
        url = `${baseURLAPI}/view_metadata?job_id=${jobId}`;
      }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("res", res);
          setModels(res.data.models);
          setImgSize([res.data.img_size[1], res.data.img_size[0]]);
          setNumImages(res.data.num_images);
          if (showCropped) setCropped(res.data.cropped);
          let resConfigs = res.data.configs;
          if (showCropped)
            for (let configName in resConfigs) {
              let config = resConfigs[configName];
              for (let stone of config) {
                console.log("centroid1", stone["centroid"]);
                stone["centroid"][0] -= res.data.cropped[0];
                stone["centroid"][1] -= res.data.cropped[1];
                stone["centroid"][2] -= res.data.cropped[2];
                stone["bbox"][0] -= res.data.cropped[0];
                stone["bbox"][1] -= res.data.cropped[1];
                stone["bbox"][2] -= res.data.cropped[2];
                stone["bbox"][3] -= res.data.cropped[0];
                stone["bbox"][4] -= res.data.cropped[1];
                stone["bbox"][5] -= res.data.cropped[2];
                console.log("centroid2", stone["centroid"]);
              }
            }
          // if cropped update stone centroid and bbox
          setConfigs(resConfigs);
          // let configLength = Object.keys(res.data.configs).length;
          setConfig(Object.keys(resConfigs)[0]);
          setStones(Object.values(resConfigs)[0]);
          setErpVolumes(res.data.erp_volumes);
          setsmtvalue(res.data.smoothness_factor);
          setNumVisitors(res.data.qr_visitors);
          let labels2 = {};
          labels2["kub"] = res.data.labels["kub"];
          labels2["stone"] = [];
          for (let i = 0; i < Object.values(res.data.configs)[0].length; i++) {
            labels2["stone"].push("Stone " + (i + 1));
          }
          setLabels(labels2);

          let filteredStones = res.data.filtered_stones;
          for (let i = 0; i < filteredStones.length; i++) {
            filteredStones[i].added = false;
            if (showCropped) {
              filteredStones[i].centroid[0] -= res.data.cropped[0];
              filteredStones[i].centroid[1] -= res.data.cropped[1];
              filteredStones[i].centroid[2] -= res.data.cropped[2];
            }
          }
          console.log("filteredStones", filteredStones);
          setFilteredStones(filteredStones);

          let modelLabelsColors = {};
          let labelToVals2 = {};
          let switchLabelsState2 = {};
          for (let model of res.data.models) {
            modelLabelsColors[model] = {};
            labelToVals2[model] = {};
            switchLabelsState2[model] = {};
            for (let i = 0; i < labels2[model].length; i++) {
              if (model === "stone") {
                const label = "Stone " + (i + 1);
                modelLabelsColors[model][label] = stoneColors[i];
              } else {
                const label = labels2[model][i];
                modelLabelsColors[model][label] = allColors[i];
              }
              labelToVals2[model][i + 1] = labels2[model][i];
              switchLabelsState2[model][labels2[model][i]] = false;
            }
          }

          console.log("modelLabelsColors", modelLabelsColors);
          setLabelsColors(modelLabelsColors);
          setLabelToVals(labelToVals2);
          setSwitchLabelsState(switchLabelsState2);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [baseURLAPI, reload]);

  const getConfigs = (jobId, center) => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    axios
      .get(`${apiURLs[center]}/configs_sent?job_id=${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("configs", res.data);
        let configs_sent = res.data.configs;
        for (let i in configs_sent) {
          let gmtDate = new Date(configs_sent[i].config_time);
          let istDate = gmtDate.toLocaleString("en-US", {
            timeZone: "UTC",
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          });
          configs_sent[i].config_time = istDate;
        }
        console.log("IST time configs", configs_sent);
        setReportConfigs(configs_sent);
        setConfigModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadLabels = async () => {
    console.log("loadLabels", Date.now());
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    for (let i = 0; i < models.length; i++) {
      // const response = await fetch(
      //   `${baseURLAPI}/view_label?job_id=${jobId}&model=${models[i]}&config_name=${config}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //     responseType: "arraybuffer",
      //   }
      // );
      // const data = await response.arrayBuffer();
      // const png = new PNG({ filterType: 1 });
      // console.log("got data", models[i], Date.now());
      // png.parse(data, function (err, data) {
      //   console.log("parsed", data.data.length, Date.now());
      //   if (err) {
      //     console.log(err);
      //   } else {
      //     setLabelsImages((labelsImages) => ({
      //       ...labelsImages,
      //       [models[i]]: data.data,
      //     }));
      //   }
      // });
      let url;
      if (showCropped) {
        url = `${baseURLAPI}/view_label2_cropped?job_id=${jobId}&model=${models[i]}&config_name=${config}`;
      } else {
        url = `${baseURLAPI}/view_label2?job_id=${jobId}&model=${models[i]}&config_name=${config}`;
      }
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const sparseData = response.data.data;
      console.log("response", sparseData.length);

      let labelImg = new Uint8Array(numImages * imgSize[1] * imgSize[0]);
      let s = Date.now();
      for (let j = 0; j < sparseData.length; j++) {
        // console.log(j);
        // if(models[i] == 'stone'){
        //   console.log(sparseData[j][0], sparseData[j][1]);
        // }
        labelImg[sparseData[j][0]] = sparseData[j][1];
      }
      console.log("Update time", Date.now() - s, "ms");

      setLabelsImages((labelsImages) => ({
        ...labelsImages,
        [models[i]]: labelImg,
      }));

      // const worker = new Worker(workerScript);
      // const worker = new Worker(new URL('./parser-worker.js', import.meta.url));
      // worker.postMessage(data, [data]);

      // worker.onmessage = function (e) {
      //     if(e.data.error) {
      //         console.log(e.data.error);
      //     } else {
      //         setLabelsImages((labelsImages) => ({
      //             ...labelsImages,
      //             [models[i]]: e.data.data,
      //         }));
      //     }
      // };
    }
    setLabelsLoaded(true);
  };

  React.useEffect(() => {
    const setData = (body, i) => {
      // use dicomparser
      // let byteArray = new Uint8Array(body);
      // let dicom = dicomParser.parseDicom(byteArray);
      // let pixelDataElement = dicom.elements.x7fe00010;
      // let pixelData = new Uint8Array(dicom.byteArray.buffer, pixelDataElement.dataOffset, pixelDataElement.length);

      let pixelData = new Uint8Array(body);

      setNumLoaded((prevNumLoaded) => prevNumLoaded + 1);
      setImagesFetchedList((prevImagesFetchedList) => ({
        ...prevImagesFetchedList,
        [i]: pixelData,
      }));
      if (i === 20) setFetched(true);
    };

    const asyncFetch = async () => {
      // if (!labelsLoaded) {
      //     loadLabels();
      // }

      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");

      let imageURL;
      if (showCropped) {
        imageURL = `${baseURLAPI}/view_cropped?job_id=${jobId}`;
      } else {
        imageURL = `${baseURLAPI}/view?job_id=${jobId}`;
      }
      var chunks = [];
      let i = 0;

      // fetch(imageURL)
      // send bearer token
      fetch(imageURL, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          const reader = response.body.getReader();
          let res;
          res = await reader.read();
          let buffer = new Buffer(res.value.slice(9));
          let frame;
          while (true) {
            let indexOf = new Buffer(buffer).indexOf("--frame--");
            if (indexOf > -1) {
              frame = buffer.slice(0, indexOf);
              buffer = buffer.slice(indexOf + 9);
              setData(frame, i);
              i += 1;
            }

            // chunks.push(res.value);
            res = await reader.read();

            if (res.done) {
              console.log("Done!");
              break;
            }
            buffer = Buffer.concat([buffer, new Buffer(res.value)]);
          }

          while (true) {
            // what is rest of buffer...
            let indexOf = new Buffer(buffer).indexOf("--frame--");
            if (indexOf > -1) {
              frame = buffer.slice(0, indexOf);
              buffer = buffer.slice(indexOf + 9);
              setData(frame, i);
              i += 1;
            } else {
              // end of buffer...
              setData(buffer, i);
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    };
    // if (numLoaded[artefactModel] % 20 == 0)
    if (numLoaded === 0 && numImages > 0 && !fetched) {
      asyncFetch();
      loadLabels();
    }
  }, [numLoaded, numImages]);

  React.useEffect(() => {
    if (numLoaded === numImages && numImages > 0) {
      setImageData3D(new Uint8Array(imgSize[0] * imgSize[1] * numImages * 2));
      let newData = new Uint8Array(imgSize[0] * imgSize[1] * numImages * 2);
      for (let j = 0; j < Object.keys(imagesFetchedList).length - 1; j++) {
        if (j in imagesFetchedList) {
          newData.set(
            imagesFetchedList[String(j)],
            j * imgSize[0] * imgSize[1] * 2
          );
        } else {
          console.log("not in", j);
        }
      }
      setImageData3D(newData);
      setSelectedImage({
        axial: parseInt(numImages / 2),
        coronal: parseInt(imgSize[0] / 2),
        sagittal: parseInt(imgSize[1] / 2),
      });
      setLoaded(true);
    }
  }, [numLoaded, imagesFetchedList]);

  let axialImgRef = null;
  let coronalImgRef = null;
  let sagittalImgRef = null;
  let popperImgRef = null;
  let pdfRef = React.useRef(null);

  const extractLittleEndian = (lower, higher, length) => {
    // check if higher byte is negative (fist bit is 1)
    // let isNegative = higher & 0x80;
    // if (isNegative) {
    //     // if negative, then flip all bits
    //     lower = 256 - lower;
    //     higher = 255 - higher;
    // }
    // let value = higher * 256 + lower;
    // if (isNegative) {
    //     value = -value;
    // }
    // return value;
    let val = higher * 256 + lower;
    val = val - 8192;
    return val;
  };

  const updateConfigs = (x) => {
    // setStones(configs[i]);
    setStones(configs[x.stone]);
    setConfig(x.stone);

    let imageURL = `${baseURLAPI}/view_label?job_id=${jobId}&config=${x.i}`;
  };

  const handleFullscreenClick = (plane) => {
    if (dimensions.width < 450) return;
    if (!fullscreen) {
      setFullscreen(plane);
      setWidth((prevWidth) => ({
        ...prevWidth,
        [plane]: 800,
      }));
      setHeight((prevHeight) => ({
        ...prevHeight,
        [plane]: 800,
      }));
    } else {
      setFullscreen(false);
      setWidth((prevWidth) => ({
        ...prevWidth,
        [plane]: 400,
      }));
      setHeight((prevHeight) => ({
        ...prevHeight,
        [plane]: 400,
      }));
    }
  };

  const handleZoom = (e, plane) => {
    setPopperAnchor(e.target);
    setPopperOpen(true);

    // draw zoomed image on popperImgRef from that point
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let multiplier = 1;
    if (fullscreen) multiplier = 1;
    canvas.width = 200 * multiplier;
    canvas.height = 200 * multiplier;

    const rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    // x = Math.round((x / width[plane]) * imgSize[0]);
    // if (plane !== "axial") y = Math.round((y / height[plane]) * numImages);
    // else y = Math.round((y / height[plane]) * imgSize[1]);
    // let w = (100 / width[plane]) * imgSize[0];
    // let h = 100 / height[plane];
    // if (plane !== "axial") h = h * numImages;
    // else h = h * imgSize[1];
    // w = Math.round(w);
    // h = Math.round(h);
    let w = 100 / width[plane];
    let h = 100 / height[plane];
    if (plane === "axial") {
      x = Math.round((x / width[plane]) * imgSize[1]);
      y = Math.round((y / height[plane]) * imgSize[0]);
      w = w * imgSize[1];
      h = h * imgSize[0];
    } else if (plane === "coronal") {
      x = Math.round((x / width[plane]) * imgSize[1]);
      y = Math.round((y / height[plane]) * numImages);
      w = w * imgSize[1];
      h = h * numImages;
    } else if (plane === "sagittal") {
      x = Math.round((x / width[plane]) * imgSize[0]);
      y = Math.round((y / height[plane]) * numImages);
      w = w * imgSize[0];
      h = h * numImages;
    }

    setPopperPosition({ x: 0, y: 0 });

    // ctx.scale(2, 2);
    // ctx.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    // ctx.drawImage(e.target, x - 50, y - 50, 100, 100, 0, 0, 200, 200);
    ctx.drawImage(
      e.target,
      x - Math.round(w / 2),
      y - Math.round(h / 2),
      w,
      h,
      0,
      0,
      200 * multiplier,
      200 * multiplier
    );
    // draw center point with opacity 0.5
    ctx.beginPath();
    ctx.arc(100 * multiplier, 100 * multiplier, 5, 0, 2 * Math.PI, false);
    ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
    ctx.fill();

    if (popperImgRef !== null) {
      popperImgRef.width = 200 * multiplier;
      popperImgRef.height = 200 * multiplier;
      popperImgRef.style.borderRadius = 100 * multiplier + "px";
      popperImgRef.src = canvas.toDataURL();
    }
  };

  const handlePan = (e, plane) => {
    // console.log('panning....');
  };

  const drawErasePixelsToMask = (e, plane, draw) => {
    const startTime = Date.now();
    // let imageSlice = labelsImages[model].slice(labelOffset, labelOffset + imgSize[0] * imgSize[1]);
    let labelsImage = labelsImages[model];
    let val;
    if (draw === "draw") val = parseInt(editMaskOptions.selectedLabelVal) + 1;
    else val = 0;
    const rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    let rows, cols;
    if (plane === "axial") {
      rows = imgSize[0];
      cols = imgSize[1];
    } else if (plane === "coronal") {
      rows = numImages;
      cols = imgSize[1];
    } else {
      rows = numImages;
      cols = imgSize[0];
    }
    const getLabelOffset = (i, j, plane) => {
      switch (plane) {
        case "axial":
          return (
            selectedImage["axial"] * imgSize[0] * imgSize[1] +
            i * imgSize[1] +
            j
          );
        case "coronal":
          return (
            i * imgSize[0] * imgSize[1] +
            j +
            imgSize[1] * selectedImage["coronal"]
          );
        case "sagittal":
          return (
            i * imgSize[0] * imgSize[1] +
            j * imgSize[1] +
            selectedImage["sagittal"]
          );
      }
    };
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        // check if inside circle
        let x2 = j;
        let y2 = i;
        if (plane !== "axial") y2 = numImages - y2;
        x2 = (x2 * rect.width) / cols;
        y2 = (y2 * rect.height) / rows;
        let dist = Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
        // if(dist < 25){
        if (dist < editMaskOptions.drawSize / 2) {
          // console.log("inside circle", i, j);
          let index = getLabelOffset(i, j, plane);
          labelsImage[index] = val;
        }
      }
    }
    // just set the imageSlice to labelsImages[model]
    setLabelsImages((labelsImages) => ({
      ...labelsImages,
      [model]: labelsImage,
    }));
    const endTime = Date.now();
    setEdited((edited) => edited + 1);
    // console.log('draw time', endTime - startTime);
  };

  const handleDrawErase = (e, plane, draw, drag) => {
    const startTimestamp = Date.now();
    // remove previous circle
    let circle = document.getElementById("circle");
    if (circle !== null) {
      circle.remove();
    }
    circle = document.createElement("div");
    circle.id = "circle";
    circle.style.position = "absolute";
    circle.style.top = `${e.clientY}px`;
    circle.style.left = `${e.clientX}px`;
    // circle.style.width = '50px';
    // circle.style.height = '50px';
    circle.style.width = editMaskOptions.drawSize + "px";
    circle.style.height = editMaskOptions.drawSize + "px";
    circle.style.borderRadius = "50%";
    circle.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    circle.style.zIndex = "9999";
    circle.style.transform = "translate(-50%, -50%)";
    // let click propagate to img below
    circle.style.pointerEvents = "none";
    // let click propagate mouse up to img below
    circle.style.userSelect = "none";
    document.body.appendChild(circle);

    if (editMaskOptions.dragging || drag) {
      // send indices to erase/draw
      drawErasePixelsToMask(e, plane, draw);
    }
    if (drag) {
      setEditMaskOptions({ ...editMaskOptions, dragging: true });
    }
    const endTimestamp = Date.now();
    // console.log('handleDrawErase time', endTimestamp - startTimestamp);
  };

  function pointInPolygon(point, polygon) {
    let inside = false;
    // the code works as follows
    // 1. draw a horizontal ray from point to the right and count the number of intersections
    // 2. if the number of intersections is odd, then the point is inside the polygon
    // 3. if the number of intersections is even, then the point is outside the polygon
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      // for each edge
      const xi = polygon[i][0],
        yi = polygon[i][1]; // vertices of edge
      const xj = polygon[j][0],
        yj = polygon[j][1]; // vertices of edge
      const intersect =
        yi > point[1] !== yj > point[1] &&
        point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi; // check if edge intersects with ray
      if (intersect) inside = !inside; // if intersects, toggle inside flag
    }
    return inside;
  }

  const handleDrawErasePoints = (e, plane, draw) => {
    const startTime = Date.now();
    const rect = e.target.getBoundingClientRect();

    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    let rows, cols;
    if (plane === "axial") {
      rows = imgSize[0];
      cols = imgSize[1];
    } else if (plane === "coronal") {
      rows = numImages;
      cols = imgSize[1];
    } else {
      rows = numImages;
      cols = imgSize[0];
    }
    const getLabelOffset = (i, j, plane) => {
      switch (plane) {
        case "axial":
          return (
            selectedImage["axial"] * imgSize[0] * imgSize[1] +
            i * imgSize[1] +
            j
          );
        case "coronal":
          return (
            i * imgSize[0] * imgSize[1] +
            j +
            imgSize[1] * selectedImage["coronal"]
          );
        case "sagittal":
          return (
            i * imgSize[0] * imgSize[1] +
            j * imgSize[1] +
            selectedImage["sagittal"]
          );
      }
    };
    let labelsImage = labelsImages[model];
    let val;
    if (draw === "draw") val = parseInt(editMaskOptions.selectedLabelVal) + 1;
    else val = 0;
    let xMin, xMax, yMin, yMax;
    xMin = _.minBy(polygonPoints, function (o) {
      return o[0];
    })[0];
    xMax = _.maxBy(polygonPoints, function (o) {
      return o[0];
    })[0];
    yMin = _.minBy(polygonPoints, function (o) {
      return o[1];
    })[1];
    yMax = _.maxBy(polygonPoints, function (o) {
      return o[1];
    })[1];
    console.log(
      "xMin",
      xMin,
      "xMax",
      xMax,
      "yMin",
      yMin,
      "yMax",
      yMax,
      polygonPoints
    );
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        let x2 = j;
        let y2 = i;
        if (plane !== "axial") y2 = numImages - y2;
        // check if inside bounding box
        if (x2 < xMin || x2 > xMax || y2 < yMin || y2 > yMax) continue;
        // x2 = Math.round(x2 / cols * rect.width);
        // y2 = Math.round(y2 / rows * rect.height);
        // check if inside polygon
        let inside = pointInPolygon([x2, y2], polygonPoints);
        // if(x2 > 150 && y2 > 150)
        //   console.log('inside', inside, x2, y2, polygon);
        if (inside) {
          let index = getLabelOffset(i, j, plane);
          labelsImage[index] = val;
        }
      }
    }

    // just set the imageSlice to labelsImages[model]
    setLabelsImages((labelsImages) => ({
      ...labelsImages,
      [model]: labelsImage,
    }));
    const endTime = Date.now();
    setEdited((edited) => edited + 1);
    console.log("draw time", endTime - startTime);
  };

  const handleMouseEnter = () => {
    // document.body.style.overflow = "hidden";
    // const Imagewrapper = document.querySelector(".Imagewrapper");
    // Imagewrapper.style.overflowY = "hidden";
    return null;
  };

  const handleMouseLeave = () => {
    // document.body.style.overflow = "auto";
    // const Imagewrapper = document.querySelector(".Imagewrapper");
    // Imagewrapper.style.overflowY = "scroll";
    return null;
  };

  const handleWheel = (e, plane) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (!activeTool) {
      if (e.deltaY > 0) {
        let val;
        if (plane === "axial") {
          val = Math.min(selectedImage[plane] + 1, numImages);
        } else if (plane === "coronal") {
          val = Math.min(selectedImage[plane] + 1, imgSize[0]);
        } else {
          val = Math.min(selectedImage[plane] + 1, imgSize[1]);
        }
        setPlane(plane);
        setSelectedImage({ ...selectedImage, [plane]: val });
      } else {
        const val = Math.max(selectedImage[plane] - 1, 0);
        setPlane(plane);
        setSelectedImage({ ...selectedImage, [plane]: val });
      }
    }
  };

  const handleAddStone = (sagittal, coronal, axial, bbox) => {
    setAddStone(false);
    setAddingStone(true);
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    const data = {
      job_id: jobId,
      stone_config: config,
      stone_centroid: [sagittal, imgSize[0] - coronal, axial],
    };
    if (showCropped) {
      data["stone_centroid"][0] += cropped[0];
      data["stone_centroid"][1] += cropped[1];
      data["stone_centroid"][2] += cropped[2];
    }
    if (addStoneMethod === "Bbox") {
      data["bbox"] = bbox;
      data["bbox"]["axial"][0]["y"] =
        imgSize[0] - data["bbox"]["axial"][0]["y"];
      data["bbox"]["axial"][1]["y"] =
        imgSize[0] - data["bbox"]["axial"][1]["y"];

      data["bbox"]["sagittal"][0]["x"] =
        imgSize[0] - data["bbox"]["sagittal"][0]["x"];
      data["bbox"]["sagittal"][1]["x"] =
        imgSize[0] - data["bbox"]["sagittal"][1]["x"];

      data["bbox"]["coronal"][0]["y"] =
        numImages - data["bbox"]["coronal"][0]["y"];
      data["bbox"]["coronal"][1]["y"] =
        numImages - data["bbox"]["coronal"][1]["y"];

      data["bbox"]["sagittal"][0]["y"] =
        numImages - data["bbox"]["sagittal"][0]["y"];
      data["bbox"]["sagittal"][1]["y"] =
        numImages - data["bbox"]["sagittal"][1]["y"];
      if (showCropped) {
        data["bbox"]["axial"][0]["x"] += cropped[0];
        data["bbox"]["axial"][0]["y"] += cropped[1];
        data["bbox"]["axial"][1]["x"] += cropped[0];
        data["bbox"]["axial"][1]["y"] += cropped[1];

        data["bbox"]["coronal"][0]["x"] += cropped[0];
        data["bbox"]["coronal"][0]["y"] += cropped[2];
        data["bbox"]["coronal"][1]["x"] += cropped[0];
        data["bbox"]["coronal"][1]["y"] += cropped[2];

        data["bbox"]["sagittal"][0]["x"] += cropped[1];
        data["bbox"]["sagittal"][0]["y"] += cropped[2];
        data["bbox"]["sagittal"][1]["x"] += cropped[1];
        data["bbox"]["sagittal"][1]["y"] += cropped[2];
      }
    }

    axios
      .post(`${baseURLAPI}/stone_info`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // stone id, max stone id + 1
        if (res.status !== 200) {
          console.log("error", res);
          setAddStoneError(res.data.error);
          setTimeout(() => {
            setAddStoneError(false);
          }, 5000);
          return;
        }
        const stoneIds = stones.map((s) => s.stone_id);
        let maxId;
        if (stoneIds.length === 0) maxId = 0;
        else maxId = Math.max(...stoneIds);
        const stoneId = parseInt(maxId) + 1;
        const newStone = {
          centroid: res.data.centroid,
          original_centroid: [sagittal, imgSize[1] - coronal, axial],
          size: res.data.size.join(" x "),
          location: res.data.distance || res.data.organ,
          volume: Math.round(res.data.volume * 100) / 100,
          hu_value: res.data.hu_val,
          side: res.data.side,
          num_stones: res.data.num_stones,
          staghorn: res.data.staghorn,
          bbox: res.data.bbox,
          stone_id: stoneId,
          lr_ap_si: res.data.lr_ap_si,
          distance: res.data.distance,
        };
        if (showCropped) {
          newStone["centroid"][0] -= cropped[0];
          newStone["centroid"][1] -= cropped[1];
          newStone["centroid"][2] -= cropped[2];
          newStone["original_centroid"][0] -= cropped[0];
          newStone["original_centroid"][1] -= cropped[1];
          newStone["original_centroid"][2] -= cropped[2];
          newStone["bbox"][0] -= cropped[0];
          newStone["bbox"][1] -= cropped[1];
          newStone["bbox"][2] -= cropped[2];

          newStone["bbox"][3] -= cropped[0];
          newStone["bbox"][4] -= cropped[1];
          newStone["bbox"][5] -= cropped[2];
        }
        const objText = res.data.obj_text;
        // create new actor from objText
        const reader = vtkOBJReader.newInstance();
        reader.parseAsText(objText);
        const mapper = vtkMapper.newInstance();
        mapper.setInputConnection(reader.getOutputPort());
        const actor = vtkActor.newInstance();
        actor.setMapper(mapper);
        actor.getProperty().setOpacity(0.5);
        actor.getProperty().setColor(1, 1, 1);
        actor.setOrientation(120, 90, 0);

        const scene = {
          name: "stone_" + stoneId,
          actor: actor,
          mapper: mapper,
        };

        setScene((prev) => [...prev, scene]);

        context.current.renderer.addActor(actor);

        const newStones = stones.concat(newStone);
        setStones(newStones);
        // add to labels
        const newLabels = labels;
        newLabels["stone"].push("Stone " + stoneId);
        setLabels(newLabels);
        // set colors, labelToVals, switchLabelsState
        const newLabelsColors = labelsColors;
        labelsColors["stone"]["Stone " + stoneId] = stoneColors[stoneId - 1];
        setLabelsColors(newLabelsColors);
        const newLabelToVals = labelToVals;
        newLabelToVals["stone"][stoneId] = stoneId;
        setLabelToVals(newLabelToVals);
        const newSwitchLabelsState = switchLabelsState;
        newSwitchLabelsState["stone"][stoneId] = false;
        setSwitchLabelsState(newSwitchLabelsState);
      })
      .catch((error) => {
        console.log("error", error);

        setAddStoneError(error.response.data.error);
        setTimeout(() => {
          setAddStoneError(false);
        }, 5000);
      })
      .finally(() => {
        setAddingStone(false);
      });
  };

  const handleOnClick = (e, plane) => {
    let otherPlanes = ["axial", "coronal", "sagittal"];
    otherPlanes = otherPlanes.filter((p) => p !== plane);

    const rect = e.target.getBoundingClientRect();
    const x = parseInt(e.clientX - rect.left);
    const y = parseInt(e.clientY - rect.top);
    let axial, coronal, sagittal, axial2, coronal2, sagittal2;
    if (plane === "axial") {
      axial = selectedImage["axial"];
      axial2 = selectedImage["axial"];
      coronal = Math.round(y / (height[plane] / imgSize[1]));
      sagittal = Math.round(x / (width[plane] / imgSize[0]));
      coronal2 = Math.round(y / (rect.height / imgSize[0]));
      sagittal2 = Math.round(x / (rect.width / imgSize[1]));
    } else if (plane === "coronal") {
      axial = numImages - Math.round(y / (height[plane] / numImages));
      axial2 = numImages - Math.round(y / (rect.height / numImages));
      coronal = selectedImage["coronal"];
      coronal2 = selectedImage["coronal"];
      sagittal = Math.round(x / (width[plane] / imgSize[0]));
      sagittal2 = Math.round(x / (rect.width / imgSize[1]));
    } else {
      axial = numImages - Math.round(y / (height[plane] / numImages));
      axial2 = numImages - Math.round(y / (rect.height / numImages));
      coronal = Math.round(x / (width[plane] / imgSize[0]));
      coronal2 = Math.round(x / (rect.width / imgSize[0]));
      sagittal = selectedImage["sagittal"];
      sagittal2 = selectedImage["sagittal"];
    }
    if (addStone && addStoneMethod === "Point") {
      handleAddStone(sagittal2, coronal2, axial2);
      return;
    } else if (!addStone && !measureLength) {
      setSelectedImage({
        axial: axial2,
        coronal: coronal2,
        sagittal: sagittal2,
      });
    }
  };

  const handleOnClickPolygon = (e, plane) => {
    const rect = e.target.getBoundingClientRect();
    let x = parseInt(e.clientX - rect.left);
    let y = parseInt(e.clientY - rect.top);
    let axial2, coronal2, sagittal2;
    if (plane === "axial") {
      y = Math.round(y / (rect.height / imgSize[0]));
      x = Math.round(x / (rect.width / imgSize[1]));
      setPolygonPoints((prev) => [...prev, [x, y]]);
    } else if (plane === "coronal") {
      axial2 = Math.round(y / (rect.height / numImages));
      sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      setPolygonPoints((prev) => [...prev, [sagittal2, axial2]]);
    } else {
      axial2 = Math.round(y / (rect.height / numImages));
      coronal2 = Math.round(x / (rect.width / imgSize[0]));
      setPolygonPoints((prev) => [...prev, [coronal2, axial2]]);
    }
  };

  // console.log('imgSize', imgSize);

  const imageWindow = (val, wl, ww) => {
    // val = val - 4096;
    const ll = wl - ww / 2;
    const ul = wl + ww / 2;
    if (val < ll) val = ll;
    else if (val > ul) val = ul;
    return ((val - ll) / (ul - ll)) * 255;
  };

  const drawOverlays = (imageData, selectedImage) => {
    if (imgSize[0] == 0 || imgSize[1] == 0) {
      console.log("imgSize is 0 returning");
      return;
    }

    // get new canvas

    const updateLabels = (overlaidImageData, labelIndex, imgIndex) => {
      for (let model of models) {
        let labelVal = labelsImages[model][labelIndex];
        if (
          labelVal < 1 ||
          !(labelVal in labelToVals[model]) ||
          switchLabelsState[model][labelToVals[model][labelVal]] === false
        ) {
          continue;
        }
        let color = labelsColors[model][labelToVals[model][labelVal]];
        overlaidImageData[imgIndex] =
          overlaidImageData[imgIndex] * (1 - labelsOpacity) +
          color[0] * labelsOpacity;
        overlaidImageData[imgIndex + 1] =
          overlaidImageData[imgIndex + 1] * (1 - labelsOpacity) +
          color[1] * labelsOpacity;
        overlaidImageData[imgIndex + 2] =
          overlaidImageData[imgIndex + 2] * (1 - labelsOpacity) +
          color[2] * labelsOpacity;
      }
      return overlaidImageData;
    };

    if (numLoaded < numImages) {
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let overlaidImageData = new Uint8Array(imgSize[0] * imgSize[1] * 4);
      // for (let i = 0; i < imgSize[0] * imgSize[1]; i++) {
      //     let index = i * 4;
      //     const offset = selectedImage['axial'] * imgSize[0] * imgSize[1] * 4;
      //     // let val = imageData[index] + imageData[index + 1] * 256;
      //     // val = window(val, windowVals[0], windowVals[1]);
      //     // overlaidImageData[index] = overlaidImageData[index + 1] = overlaidImageData[index + 2] = val; // * (1. - lobesOpacity);

      //     overlaidImageData[index] = imageData[index + offset];
      //     overlaidImageData[index + 1] = imageData[index + offset + 1];
      //     overlaidImageData[index + 2] = imageData[index + offset + 2];

      //     overlaidImageData[index + 3] = 255;
      //     // overlaidImageData = updateLabels(overlaidImageData, offset + index, index);
      //     // overlaidImageData = updateLabels(overlaidImageData, offset * 2 + index, index);
      // }
      for (let i = 0; i < imgSize[0] * imgSize[1]; i++) {
        // overlaidImageData[i] = imagesFetchedList[String(selectedImage['axial'])][i];
        const index1 = i * 2;
        const index2 = i * 4;
        let val = extractLittleEndian(
          imagesFetchedList[String(selectedImage["axial"])][index1],
          imagesFetchedList[String(selectedImage["axial"])][index1 + 1]
        );
        val = imageWindow(val, windowVals[0], windowVals[1]);

        overlaidImageData[index2] =
          overlaidImageData[index2 + 1] =
          overlaidImageData[index2 + 2] =
            val;
        overlaidImageData[index2 + 3] = 255;
      }
      let img = new ImageData(
        new Uint8ClampedArray(overlaidImageData),
        imgSize[1],
        imgSize[0]
      );
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.putImageData(img, 0, 0);
      let src = canvas.toDataURL("image/jpeg", 0.99);

      axialImgRef.src = src;
    } else {
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let overlaidImageData;
      overlaidImageData = new Uint8Array(imgSize[0] * imgSize[1] * 4);
      if (!fullscreen || fullscreen === "axial") {
        // const offset = selectedImage['axial'] * imgSize[0] * imgSize[1] * 4;
        const offset = selectedImage["axial"] * imgSize[0] * imgSize[1] * 2;
        const labelOffset = selectedImage["axial"] * imgSize[0] * imgSize[1];
        for (let i = 0; i < imgSize[0] * imgSize[1]; i++) {
          let index = i * 4,
            index2 = i * 2;

          // let val = imageData[offset + index] + imageData[offset + index + 1] * 256;
          let val = extractLittleEndian(
            imageData[offset + index2],
            imageData[offset + index2 + 1]
          );

          val = imageWindow(val, windowVals[0], windowVals[1]);
          overlaidImageData[index] =
            overlaidImageData[index + 1] =
            overlaidImageData[index + 2] =
              val; // * (1. - lobesOpacity);

          overlaidImageData[index + 3] = 255;
          // overlaidImageData = updateLabels(overlaidImageData, offset + index, index);
          if (labelsLoaded)
            overlaidImageData = updateLabels(
              overlaidImageData,
              // offset * 2 + index,
              labelOffset + i,
              index
            );
        }
        let img = new ImageData(
          new Uint8ClampedArray(overlaidImageData),
          imgSize[1],
          imgSize[0]
        );
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.putImageData(img, 0, 0);
        if (
          stone !== null &&
          parseInt(stone["centroid"][2]) === selectedImage["axial"]
        ) {
          let centroid = stone["centroid"].slice();
          let sizes;
          if (stone.size) sizes = stone["size"].split(" x ").map(parseFloat);
          else sizes = [10, 10, 10];
          centroid[1] = imgSize[0] - centroid[1];
          ctx.beginPath();
          ctx.rect(
            centroid[0] - sizes[0] / 2 - 6,
            centroid[1] - sizes[1] / 2 - 6,
            sizes[0] + 12,
            sizes[1] + 12
          );
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (
          addStone &&
          addStoneMethod === "Bbox" &&
          bboxPoints["axial"] !== undefined &&
          bboxPoints["axial"].length === 2
        ) {
          const p1 = bboxPoints["axial"][0];
          const p2 = bboxPoints["axial"][1];
          ctx.beginPath();
          ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (crossHairOn) {
          // draw lines
          ctx.beginPath();
          ctx.moveTo(0, selectedImage["coronal"]);
          ctx.lineTo(imgSize[1], selectedImage["coronal"]);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(selectedImage["sagittal"], 0);
          ctx.lineTo(selectedImage["sagittal"], imgSize[0]);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (measureLength) {
          // Draw the line
          const startPoint = linemeasure["axial"][0];
          const endPoint = linemeasure["axial"][1];
          ctx.beginPath();
          ctx.moveTo(startPoint.x, startPoint.y);
          ctx.lineTo(endPoint.x, endPoint.y);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
          let lenNum = calculateDistance(
            startPoint,
            endPoint,
            dicomMetadata["PixelSpacing"].split(","),
            dicomMetadata["SliceThickness"]
          );

          if (lenNum) {
            const midPoint = {
              x: (startPoint.x + endPoint.x) / 2,
              y: (startPoint.y + endPoint.y) / 2,
            };
            ctx.font = "bold 20px Arial";

            // Calculate text width
            const textWidth = ctx.measureText(lenNum).width;

            // Draw white background for text
            ctx.fillStyle = "white";
            // ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            ctx.fillRect(midPoint.x + 10, midPoint.y - 5, textWidth + 20, 40);
            ctx.fillStyle = "red";
            ctx.fillText(lenNum, midPoint.x + 20, midPoint.y + 20);
          }
        }
        axialImgRef.src = canvas.toDataURL("image/jpeg", 0.99);
      }
      // console.log(imageData.length, labelsImages['kub'].length, 'lengths');
      if (!fullscreen || fullscreen === "coronal") {
        let canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let overlaidImageData;
        overlaidImageData = new Uint8Array(numImages * imgSize[1] * 4);
        for (let i = 0; i < numImages; i++) {
          for (let j = 0; j < imgSize[1]; j++) {
            let offset =
              (i * imgSize[0] * imgSize[1] +
                j +
                imgSize[1] * selectedImage["coronal"]) *
              2;
            let labelOffset =
              i * imgSize[0] * imgSize[1] +
              j +
              imgSize[1] * selectedImage["coronal"];
            let index = ((numImages - i) * imgSize[1] + j) * 4;
            // let val = imageData[offset ] + imageData[offset + 1] * 256;
            let val = extractLittleEndian(
              imageData[offset],
              imageData[offset + 1]
            );
            val = imageWindow(val, windowVals[0], windowVals[1]);

            overlaidImageData[index] =
              overlaidImageData[index + 1] =
              overlaidImageData[index + 2] =
                val; // * (1. - lobesOpacity);
            overlaidImageData[index + 3] = 255;
            // overlaidImageData = updateLabels(overlaidImageData, offset, index);
            if (labelsLoaded)
              overlaidImageData = updateLabels(
                overlaidImageData,
                // offset * 2,
                labelOffset,
                index
              );
          }
        }
        let img = new ImageData(
          new Uint8ClampedArray(overlaidImageData),
          imgSize[1],
          numImages
        );
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.putImageData(img, 0, 0);
        if (
          stone !== null &&
          parseInt(imgSize[0] - stone["centroid"][1]) ===
            selectedImage["coronal"]
        ) {
          let centroid = stone["centroid"].slice();
          let sizes;
          if (stone.size) sizes = stone["size"].split(" x ").map(parseFloat);
          else sizes = [10, 10, 10];
          centroid[2] = numImages - centroid[2];
          // centroid[0] = imgSize[0] - centroid[0];
          ctx.beginPath();
          ctx.rect(
            centroid[0] - sizes[0] / 2 - 6,
            centroid[2] - sizes[2] / 2 - 6,
            sizes[0] + 12,
            sizes[2] + 12
          );
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (
          addStone &&
          addStoneMethod === "Bbox" &&
          bboxPoints["coronal"] !== undefined &&
          bboxPoints["coronal"].length === 2
        ) {
          const p1 = bboxPoints["coronal"][0];
          const p2 = bboxPoints["coronal"][1];
          ctx.beginPath();
          ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (crossHairOn) {
          // draw lines
          ctx.beginPath();
          ctx.moveTo(
            0,
            ((numImages - selectedImage["axial"]) / numImages) * canvas.height
          );
          ctx.lineTo(
            imgSize[1],
            ((numImages - selectedImage["axial"]) / numImages) * canvas.height
          );
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(selectedImage["sagittal"], 0);
          ctx.lineTo(selectedImage["sagittal"], numImages);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (measureLength) {
          // Draw the line
          const startPoint = linemeasure["coronal"][0];
          const endPoint = linemeasure["coronal"][1];
          ctx.beginPath();
          ctx.moveTo(startPoint.x, startPoint.y);
          ctx.lineTo(endPoint.x, endPoint.y);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
          let lenNum = calculateDistance(
            startPoint,
            endPoint,
            dicomMetadata["PixelSpacing"].split(","),
            dicomMetadata["SliceThickness"]
          );
          if (lenNum) {
            const midPoint = {
              x: (startPoint.x + endPoint.x) / 2,
              y: (startPoint.y + endPoint.y) / 2,
            };
            ctx.font = "bold 20px Arial";

            // Calculate text width
            const textWidth = ctx.measureText(lenNum).width;

            // Draw white background for text
            ctx.fillStyle = "white";
            // ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            ctx.fillRect(midPoint.x + 10, midPoint.y - 5, textWidth + 20, 40);
            ctx.fillStyle = "red";
            ctx.fillText(lenNum, midPoint.x + 20, midPoint.y + 20);
          }
        }

        coronalImgRef.src = canvas.toDataURL("image/jpeg", 0.99);
      }
      if (!fullscreen || fullscreen === "sagittal") {
        let canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        let overlaidImageData;
        overlaidImageData = new Uint8Array(numImages * imgSize[0] * 4);
        for (let i = 0; i < numImages; i++) {
          for (let j = 0; j < imgSize[0]; j++) {
            let offset =
              (i * imgSize[0] * imgSize[1] +
                j * imgSize[1] +
                selectedImage["sagittal"]) *
              2;
            let labelOffset =
              i * imgSize[0] * imgSize[1] +
              j * imgSize[1] +
              selectedImage["sagittal"];
            let index = ((numImages - i) * imgSize[0] + j) * 4;
            // let val = imageData[offset ] + imageData[offset + 1] * 256;
            let val = extractLittleEndian(
              imageData[offset],
              imageData[offset + 1]
            );
            val = imageWindow(val, windowVals[0], windowVals[1]);
            // let index = (i * imgSize[0] + j) * 4;
            // let index = ((numImages - i) * imgSize[1] + (imgSize[0] - j)) * 4;

            overlaidImageData[index] =
              overlaidImageData[index + 1] =
              overlaidImageData[index + 2] =
                val; // * (1. - lobesOpacity);
            overlaidImageData[index + 3] = 255;
            // overlaidImageData = updateLabels(overlaidImageData, offset, index);
            if (labelsLoaded)
              overlaidImageData = updateLabels(
                overlaidImageData,
                // offset * 2,
                labelOffset,
                index
              );
          }
        }
        let img = new ImageData(
          new Uint8ClampedArray(overlaidImageData),
          imgSize[0],
          numImages
        );
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.putImageData(img, 0, 0);
        if (
          stone !== null &&
          parseInt(stone["centroid"][0]) === selectedImage["sagittal"]
        ) {
          let centroid = stone["centroid"].slice();
          let sizes;
          if (stone.size) sizes = stone["size"].split(" x ").map(parseFloat);
          else sizes = [10, 10, 10];
          ctx.beginPath();
          centroid[1] = imgSize[0] - centroid[1];
          centroid[2] = numImages - centroid[2];
          ctx.rect(
            centroid[1] - sizes[1] / 2 - 6,
            centroid[2] - sizes[2] / 2 - 6,
            sizes[1] + 12,
            sizes[2] + 12
          );
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (
          addStone &&
          addStoneMethod === "Bbox" &&
          bboxPoints["sagittal"] !== undefined &&
          bboxPoints["sagittal"].length === 2
        ) {
          const p1 = bboxPoints["sagittal"][0];
          const p2 = bboxPoints["sagittal"][1];
          ctx.beginPath();
          ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (crossHairOn) {
          // draw lines
          ctx.beginPath();
          ctx.moveTo(0, numImages - selectedImage["axial"]);
          ctx.lineTo(imgSize[0], numImages - selectedImage["axial"]);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
          ctx.beginPath();
          ctx.moveTo(selectedImage["coronal"], 0);
          ctx.lineTo(selectedImage["coronal"], numImages);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "red";
          ctx.stroke();
        }
        if (measureLength) {
          // Draw the line
          const startPoint = linemeasure["sagittal"][0];
          const endPoint = linemeasure["sagittal"][1];
          ctx.beginPath();
          ctx.moveTo(startPoint.x, startPoint.y);
          ctx.lineTo(endPoint.x, endPoint.y);
          ctx.strokeStyle = "red";
          ctx.lineWidth = 2;
          ctx.stroke();
          let lenNum = calculateDistance(
            startPoint,
            endPoint,
            dicomMetadata["PixelSpacing"].split(","),
            dicomMetadata["SliceThickness"]
          );
          if (lenNum) {
            const midPoint = {
              x: (startPoint.x + endPoint.x) / 2,
              y: (startPoint.y + endPoint.y) / 2,
            };
            ctx.font = "bold 20px Arial";

            // Calculate text width
            const textWidth = ctx.measureText(lenNum).width;

            // Draw white background for text
            ctx.fillStyle = "white";
            // ctx.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
            ctx.fillRect(midPoint.x + 10, midPoint.y - 5, textWidth + 20, 40);
            ctx.fillStyle = "red";
            ctx.fillText(lenNum, midPoint.x + 20, midPoint.y + 20);
          }
        }
        sagittalImgRef.src = canvas.toDataURL("image/jpeg", 0.99);
      }
    }

    // setLoaded(true);
  };

  React.useEffect(() => {
    const asyncDraw = async () => {
      // const canvas = document.getElementById('canvas');
      // if (numLoaded >= numImages) {

      drawOverlays(imageData3D, selectedImage);
      // setFetched(true);
      // }
    };
    asyncDraw();
  }, [
    windowVals,
    fetched,
    selectedImage,
    imagesFetchedList,
    loaded,
    stone,
    switchLabelsState,
    bboxPoints,
    linemeasure,
    fullscreen,
    crossHairOn,
    edited,
  ]);

  // React.useEffect(() => {
  //     if (loaded)
  //         loadLabels();
  // }, [loaded]);

  /// 3D viewer
  React.useEffect(() => {
    if (loaded) {
      let modelPath;
      modelPath = `${baseURLAPI}/models`;
      axios
        .get(modelPath)
        .then((res) => {
          setModels(res.data.models);
          setModel(res.data.models[0]);
          if (!loadedOnce) {
            setFullScreenRenderer(
              vtkFullScreenRenderWindow.newInstance({
                rootContainer: vtkContainerRef.current,
                background: [0, 0, 0], //[220, 220, 220]
              })
            );
            setWindowInteractor(vtkRenderWindowInteractor.newInstance());
          }
        })
        .catch((err) => {
          console.log("Error in fetching models", err);
        });
    }
  }, [loaded]);

  React.useEffect(() => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    axios
      .get(`${baseURLAPI}/admin/job_sequences/${jobId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(
          "metaData_seq",
          res.data.metadata[res.data["selected_sequence"]]
        );
        setDicomMetadata(res.data.metadata[res.data["selected_sequence"]]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [baseURLAPI]);

  React.useEffect(() => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    if (models.filter((model) => model.startsWith("stone")).length > 0)
      axios
        .get(`${baseURLAPI}/json?job_id=${jobId}&config=${config}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let newStones = {};
          for (let i = 0; i < res.data.stones.length; i++) {
            newStones[`stone_${i + 1}`] = res.data.stones[i];
          }
          setStonesData(newStones);
          setPatientName(res.data.patient.name);

          let stoneLabels = [];
          for (let i = 0; i < Object.values(newStones).length; i++) {
            stoneLabels.push("Stone " + (i + 1));
          }

          let labels2 = { ...labels };
          labels2["stone"] = stoneLabels;
          setLabels(labels2);

          let labelsColors2 = { ...labelsColors };
          let labelToVals2 = { ...labelToVals };
          let switchLabelsState2 = { ...switchLabelsState };
          labelsColors2["stone"] = {};
          labelToVals2["stone"] = {};
          switchLabelsState2["stone"] = {};
          for (let i = 0; i < stoneLabels.length; i++) {
            const label = stoneLabels[i];
            labelsColors2["stone"][label] = stoneColors[i];
            labelToVals2["stone"][i + 1] = label;
            switchLabelsState2["stone"][label] = false;
          }
          setLabelsColors(labelsColors2);
          setLabelToVals(labelToVals2);
          setSwitchLabelsState(switchLabelsState2);
        })
        .catch((err) => {
          console.log(err);
        });
    if (loaded) loadLabels();
  }, [config]);

  // React.useEffect(() => {
  //   if (shouldLoadLabels) loadLabels();
  // }, [shouldLoadLabels]);

  React.useEffect(() => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    // console.log('token', token);

    if (models.filter((model) => model.startsWith("stone")).length > 0)
      axios
        .get(`${baseURLAPI}/json?job_id=${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let stones = {};
          for (let i = 0; i < res.data.stones.length; i++) {
            stones[`stone_${i + 1}`] = res.data.stones[i];
          }
          setStonesData(stones);
          // setPatientName(res.data.patient.name);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [models]);

  React.useEffect(() => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
      axios
        .get(`${baseURLAPI}/logtext?job_id=${jobId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log('logtext', res.data.log_content)
          setLogText(res.data.log_content);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [loaded]);


  React.useEffect(() => {
    if (loaded && fullScreenRenderer !== null && !eventsSet) {
      // console.log(fullScreenRenderer.getRenderer());
      const interactor = fullScreenRenderer.getRenderWindow().getInteractor();
      interactor.onMouseEnter((e) => {
        // console.log('mouse enter!!', e);
      });
      interactor.onMouseLeave((e) => {
        // console.log('mouse leave!!', e);
      });

      interactor.onMouseMove((e) => {
        const point = [e.position.x, e.position.y, 0];
        const pointPicker = vtkPicker.newInstance({
          renderer: fullScreenRenderer.getRenderer(),
          // pickFromList: [point],
          tolerance: 0.01,
        });
        pointPicker.pick(point, fullScreenRenderer.getRenderer());
        let newSceneList = {};
        for (let i = 0; i < pointPicker.getActors().length; i++) {
          const actor = pointPicker.getActors()[i];
          const name = actor.get("name").name;
          if (name.toLowerCase().startsWith("stone")) {
            // actor.getProperty().setOpacity(1.0);
            actor.getProperty().setDiffuseColor(1.0, 0.0, 0.0);
            newSceneList[name] = actor;
          }
        }
        if (Object.keys(newSceneList).length > 0) {
          setScene((oldScene) => {
            return oldScene.map((x) => {
              if (x.name in newSceneList) {
                return {
                  ...x,
                  actor: newSceneList[x.name],
                };
              } else {
                return x;
              }
            });
          });
          setModalOpen(true);
          setStoneSelected(Object.keys(newSceneList)[0]);
          context.current.renderWindow.render();
        } else {
          setScene((oldScene) => {
            return oldScene.map((x) => {
              if (
                x.name.toLowerCase().startsWith("stone") &&
                x.name in stoneColours
              ) {
                const [r, g, b] = stoneColours[x.name];
                x.actor.getProperty().setDiffuseColor(r, g, b);
                x.actor.getProperty().setAmbientColor(0, 0, 0);
                x.actor.getProperty().setSpecularColor(0, 0, 0);
                return x;
              } else {
                return x;
              }
            });
          });
          setModalOpen(false);
          context.current.renderWindow.render();
        }
      });
      setEventsSet(true);
    }
  }, [windowInteractor, fullScreenRenderer, loaded, stoneColours]); //, pointPicker]);

  const setData = (data, model_i) => {
    const objReader = vtkOBJReader.newInstance({ splitMode: "usemtl" });
    objReader.parseAsText(data.obj);

    const mtlReader = vtkMTLReader.newInstance();
    mtlReader.parseAsText(data.mtl);

    const renderer = fullScreenRenderer.getRenderer();
    const renderWindow = fullScreenRenderer.getRenderWindow();

    let sceneList = [];
    let actors = [];
    let mappers = [];
    for (let i = 0; i < objReader.getNumberOfOutputPorts(); i++) {
      const polydata = objReader.getOutputData(i);
      const name = polydata.get("name").name;
      // if(scene.filter(s => s.name === name).length === 0) {

      const mapper = vtkMapper.newInstance();
      mapper.setInputData(polydata);
      const actor = vtkActor.newInstance();

      actor.set({ name: name });
      actor.setMapper(mapper);
      mtlReader.applyMaterialToActor(name, actor);
      // if actor exists remove it
      const oldActor = renderer
        .getActors()
        .find((a) => a.get("name").name === name);
      if (oldActor) {
        renderer.removeActor(oldActor);
      }
      renderer.addActor(actor);

      actor.setOrientation(120, 90, 0);
      if (model_i.toLowerCase().startsWith("stone")) {
        actor.getProperty().setOpacity(1.0);
        // actor.getProperty().setDiffuseColor(0.0, 0.0, 1.0);
        actor.getProperty().setDiffuseColor(1.0, 1.0, 1.0);
        // actor.getProperty().setSpecularColor(0.8, 0.8, 0.8);
        setStoneColours((stoneColours) => {
          return { ...stoneColours, [name]: [1.0, 1.0, 1.0] };
        });
      } else if (model_i.toLowerCase() === "kub") {
        // actor.getProperty().setOpacity(0.5);
        actor.getProperty().setOpacity(0.3);
      } else {
        // actor.getProperty().setOpacity(0.5);
        actor.getProperty().setOpacity(0.3);
      }
      sceneList.push({ name, actor, mapper });
      actors.push(actor);
      mappers.push(mapper);
      // }
    }
    renderer.resetCamera();
    renderWindow.render();
    setModelOptions((modelOptions) => {
      return { ...modelOptions, [model_i]: sceneList };
    });

    setScene((prevList) => {
      return [...prevList, ...sceneList];
    });

    context.current = {
      renderWindow,
      renderer,
      actors,
      mappers,
      // coneSource,
    };
  };

  React.useEffect(() => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    if (model === null || fullScreenRenderer === null) {
      console.log("model or renderer null");
      return;
    }
    if (loadedOnce) {
      setObjReaders({});
      setScene([]);
      // remove all actors from renderer
      context.current.renderer.removeAllViewProps();
      context.current.renderWindow.render();
    }

    for (let model_i of models) {
      let objPath = `${baseURLAPI}/get_obj_mtl?job_id=${jobId}&model=${model_i}&file_type=obj&config=${config}&a.obj`;
      let data;
      if (objReaders[model_i] === undefined) {
        axios
          .get(objPath, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setObjReaders({ ...objReaders, [model_i]: res.data });
            setData(res.data, model_i);
            if (!loadedOnce) setLoadedOnce(true);
          })
          .catch((err) => {
            console.log("error", err);
          });
      } else {
        setData(objReaders[model_i], model_i);
      }
    }
  }, [fullScreenRenderer, config]);

  const getPdfData = async () => {
    const res = await fetch(`${baseURL}/report/${jobId}/${reportConfig}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setPdfData(res.data);

    let blob = await res.blob();

    const f = new File([blob], "report.pdf", { type: "application/pdf" });
    setPdfFile(f);
    // setPdfFile(new File([blob], 'report.pdf', { type: 'application/pdf' }));
    let url = window.URL.createObjectURL(blob);
    setReportLoading(false);
    if (pdfRef.current === null) return;
    pdfRef.current.src = url;
  };

  React.useEffect(() => {
    if (reportModalOpen && pdfRef !== null && reportMode === "view") {
      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");
      setReportLoading(true);
      if (
        typeof window !== "undefined" &&
        window.navigator &&
        window.navigator.mimeTypes &&
        window.navigator.mimeTypes["application/pdf"]
      ) {
        getPdfData();
      } else {
        getPdfData();
        axios({
          method: "GET",
          url: `${baseURL}/report/${jobId}/${reportConfig}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: 'arraybuffer'
        })
          .then((res) => {
            // download file

            // let blob = new Blob([res.data], { type: 'application/pdf' });
            // let url = window.URL.createObjectURL(blob);
            // console.log('pdfRef', pdfRef);
            // pdfRef.current.src = url;

            setPdfData(res.data);

            let blob = new Blob([res.data], { type: "application/pdf" });
            setPdfFile(
              new File([blob], "report.pdf", { type: "application/pdf" })
            );
            let url = window.URL.createObjectURL(blob);
            if (pdfRef.current === null) return;
            pdfRef.current.src = url;
          })
          .catch((err) => {
            console.log("error in fetching report", err);
          })
          .finally(() => {
            setReportLoading(false);
          });
      }
    }
  }, [reportModalOpen, reportMode, pdfRef, reportConfig]);

  React.useEffect(() => {
    if ("kub" in modelOptions && "stone" in modelOptions) {
      setLoaded(true);
    } else if ("kub_cect" in modelOptions) {
      setLoaded(true);
    }
  }, [modelOptions, scene]);

  const updateStone = (value, key) => {
    let newStone = { ...stone };
    if (key === "location" && value.toLowerCase().search("ureter") !== -1)
      newStone["side"] =
        newStone["side"].split(" ")[0] +
        " (" +
        value.split(" ")[0].toLowerCase() +
        ")";
    else newStone[key] = value;
    if (key === "ant_pos") {
      // update in locatin as well
      newStone["location"] =
        newStone["location"].split(" (")[0] + " (" + value[0] + ")";
    }
    setStone(newStone);
    let newStones = [...stones];
    newStones[stoneIndex] = newStone;
    setStones(newStones);
  };

  const onConfigSave = () => {
    setSaving(true);
    // send to backend
    let url = `${baseURLAPI}/update_config`;
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    //get stones obj from scene
    let stonesObj = [];
    for (let i = 0; i < scene.length; i++) {
      // actor is visible and is stone
      if (scene[i].actor.getVisibility() && scene[i].name.startsWith("stone")) {
        // vtkOBJExporter.newInstance().write(scene[i].actor, `${scene[i].name}.obj`);

        let verts = scene[i].actor
          .getMapper()
          .getInputData()
          .getPoints()
          .getData();
        // get triangle faces
        let faces = scene[i].actor
          .getMapper()
          .getInputData()
          .getPolys()
          .getData();
        let stone_id = scene[i].name.split("_")[1];
        stonesObj.push({
          stone_id: parseInt(stone_id),
          verts: Array.from(verts),
          faces: Array.from(faces),
        });
      }
    }
    // return;
    let data = {
      stones: stones,
      job_id: jobId,
      config: config,
      stones_obj: stonesObj,
      // email: props.currentUser.email,
    };
    if (showCropped) {
      for (let i = 0; i < data.stones.length; i++) {
        data.stones[i]["centroid"][0] += cropped[0];
        data.stones[i]["centroid"][1] += cropped[1];
        data.stones[i]["centroid"][2] += cropped[2];

        data.stones[i]["bbox"][0] += cropped[0];
        data.stones[i]["bbox"][1] += cropped[1];
        data.stones[i]["bbox"][2] += cropped[2];
        data.stones[i]["bbox"][3] += cropped[0];
        data.stones[i]["bbox"][4] += cropped[1];
        data.stones[i]["bbox"][5] += cropped[2];
      }
    }
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // download file
        // toast.success('Report saved successfully');
        // wait for task to finish
        let interval = setInterval(() => {
          // every 1 second
          axios
            .get(`${baseURLAPI}/task_status/${res.data.task_id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                clearInterval(interval);
                setSaving(false);
                setSaveStatus("Saved config successfully");
                setSavedConfig(true);
                setReload(reload + 1);
                setTimeout(() => {
                  setSaveStatus("");
                  setSavedConfig(false);
                }, 3000);
              } else if (res.data.status.startsWith("FAILED")) {
                clearInterval(interval);
                setSaving(false);
                setSaveStatus(res.data.status.replace("FAILED:", ""));
                setSavedConfig(true);
                setTimeout(() => {
                  setSaveStatus("");
                  setSavedConfig(false);
                }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              clearInterval(interval);
              setSaving(false);
              setSaveStatus("Error saving report");
              setSavedConfig(true);
              setTimeout(() => {
                setSaveStatus("");
                setSavedConfig(false);
              }, 3000);
            })
            .finally(() => {
              // setSaving(false);
            });
        }, 1000);
      })
      .catch((err) => {
        console.log(err, "err");
        setSaving(false);
        setSaveStatus("Error saving report");
        setSavedConfig(true);
        setTimeout(() => {
          setSaveStatus("");
          setSavedConfig(false);
        }, 3000);
      });
  };

  const runAgain = (url, uploadFile, runWith, useLabel) => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    let data = {
      job_id: jobId,
      config: config,
      run_with: runWith,
    };
    if (uploadFile) {
      console.log("uploading file");
      data["file"] = uploadFile;
    }
    if (useLabel) {
      let startTime = Date.now();
      let newLabel = [];
      let labelLength = labelsImages[model].length;
      for (let i = 0; i < labelLength; i++) {
        if (labelsImages[model][i] !== 0) {
          newLabel.push([i, labelsImages[model][i]]);
        }
      }
      let endTime = Date.now();
      // console.log("time taken to create label", endTime - startTime);
      data["label"] = JSON.stringify(newLabel);
      data["cropped"] = showCropped;
      // console.log("label", newLabel);
    }
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setSaveStatus("Rerunning the case, please come back later");
        setTimeout(() => {
          setSaveStatus("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setSaveStatus("Error rerunning the case");
        setTimeout(() => {
          setSaveStatus("");
        }, 3000);
      });
  };

  const runAgain_both = (url, uploadFile, uploadFile_stone) => {
    const token = admin
      ? localStorage.getItem(`token-${center}`)
      : localStorage.getItem("token");
    let data = {
      job_id: jobId,
    };
    if (uploadFile) {
      console.log("uploading file");
      data["file_postprocessed"] = uploadFile;
      data["file_stone"] = uploadFile_stone;
    }
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setSaveStatus("Rerunning the case, please come back later");
        setpreprocessedMask(null);
        setstoneMask(null);
        setTimeout(() => {
          setSaveStatus("");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setSaveStatus("Error rerunning the case");
        setTimeout(() => {
          setSaveStatus("");
        }, 3000);
      });
  };
  const handleMouseDown = (e, plane) => {
    setIsMouseDown(true);
    // Start running your function here
    if (editMaskOptions.erase) {
      if (editMaskOptions.dragging) {
        setEditMaskOptions({ ...editMaskOptions, dragging: false });
      } else {
        handleDrawErase(e, plane, "erase", true);
      }
    } else if (editMaskOptions.draw) {
      if (editMaskOptions.dragging) {
        setEditMaskOptions({ ...editMaskOptions, dragging: false });
      } else {
        handleDrawErase(e, plane, "draw", true);
      }
    } else if (editMaskOptions.drawPolygon) {
      handleOnClickPolygon(e, plane);
    }
    if (addStone && addStoneMethod === "Bbox") {
      setDrawingbbox(true);

      let otherPlanes = ["axial", "coronal", "sagittal"];
      otherPlanes = otherPlanes.filter((p) => p !== plane);

      const rect = e.target.getBoundingClientRect();
      const x = parseInt(e.clientX - rect.left);
      const y = parseInt(e.clientY - rect.top);

      let axial, coronal, sagittal, axial2, coronal2, sagittal2;
      if (plane === "axial") {
        axial = selectedImage["axial"];
        axial2 = selectedImage["axial"];
        coronal = Math.round(y / (height[plane] / imgSize[1]));
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(y / (rect.height / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else if (plane === "coronal") {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = selectedImage["coronal"];
        coronal2 = selectedImage["coronal"];
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(x / (rect.width / imgSize[0]));
        sagittal = selectedImage["sagittal"];
        sagittal2 = selectedImage["sagittal"];
      }

      let newPoint = { x: x, y: y };
      if (plane === "axial") {
        newPoint = { x: sagittal2, y: coronal2 };
      } else if (plane === "coronal") {
        newPoint = { x: sagittal2, y: numImages - axial2 };
      } else {
        newPoint = { x: coronal2, y: numImages - axial2 };
      }
      if (bboxPoints[plane] === undefined || bboxPoints[plane].length === 2) {
        setBboxPoints((prevBboxPoints) => ({
          ...prevBboxPoints,
          [plane]: [newPoint],
        }));
      } else {
        const newBboxPoints = bboxPoints[plane].concat(newPoint);

        setBboxPoints((prevBboxPoints) => ({
          ...prevBboxPoints,
          [plane]: newBboxPoints,
        }));
      }
      return;
    }
    if (!addStone && measureLength && e.button === 0) {
      setDrawingLine(true);

      let otherPlanes = ["axial", "coronal", "sagittal"];
      otherPlanes = otherPlanes.filter((p) => p !== plane);

      const rect = e.target.getBoundingClientRect();
      const x = parseInt(e.clientX - rect.left);
      const y = parseInt(e.clientY - rect.top);

      let axial, coronal, sagittal, axial2, coronal2, sagittal2;
      if (plane === "axial") {
        axial = selectedImage["axial"];
        axial2 = selectedImage["axial"];
        coronal = Math.round(y / (height[plane] / imgSize[1]));
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(y / (rect.height / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else if (plane === "coronal") {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = selectedImage["coronal"];
        coronal2 = selectedImage["coronal"];
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(x / (rect.width / imgSize[0]));
        sagittal = selectedImage["sagittal"];
        sagittal2 = selectedImage["sagittal"];
      }

      let newPoint = { x: x, y: y };
      if (plane === "axial") {
        newPoint = { x: sagittal2, y: coronal2 };
      } else if (plane === "coronal") {
        newPoint = { x: sagittal2, y: numImages - axial2 };
      } else {
        newPoint = { x: coronal2, y: numImages - axial2 };
      }
      if (linemeasure[plane] === undefined || linemeasure[plane].length === 2) {
        setLinemeasure((prevLinemeasure) => ({
          ...prevLinemeasure,
          [plane]: [newPoint],
        }));
        calculateDistance(
          newPoint,
          newPoint,
          dicomMetadata["PixelSpacing"].split(","),
          dicomMetadata["SliceThickness"]
        );
      } else {
        const newlinePoints = linemeasure[plane].concat(newPoint);

        setLinemeasure((prevlinemeasure) => ({
          ...prevlinemeasure,
          [plane]: newlinePoints,
        }));
        calculateDistance(
          newlinePoints[0],
          newlinePoints[1],
          dicomMetadata["PixelSpacing"].split(","),
          dicomMetadata["SliceThickness"]
        );
      }
      return;
    }
  };

  const handleMouseUp = (e, plane) => {
    setIsMouseDown(false);
    // Stop running your function here

    if (editMaskOptions.erase) {
      if (editMaskOptions.dragging) {
        setEditMaskOptions({ ...editMaskOptions, dragging: false });
      } else {
        handleDrawErase(e, plane, "erase", true);
      }
    } else if (editMaskOptions.draw) {
      if (editMaskOptions.dragging) {
        setEditMaskOptions({ ...editMaskOptions, dragging: false });
      } else {
        handleDrawErase(e, plane, "draw", true);
      }
    } else if (editMaskOptions.drawPolygon) {
      handleOnClickPolygon(e, plane);
    }
    if (addStone && addStoneMethod === "Bbox" && drawingbbox) {
      setDrawingbbox(false);
      let otherPlanes = ["axial", "coronal", "sagittal"];
      otherPlanes = otherPlanes.filter((p) => p !== plane);

      const rect = e.target.getBoundingClientRect();
      const x = parseInt(e.clientX - rect.left);
      const y = parseInt(e.clientY - rect.top);

      let axial, coronal, sagittal, axial2, coronal2, sagittal2;
      if (plane === "axial") {
        axial = selectedImage["axial"];
        axial2 = selectedImage["axial"];
        coronal = Math.round(y / (height[plane] / imgSize[1]));
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(y / (rect.height / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else if (plane === "coronal") {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = selectedImage["coronal"];
        coronal2 = selectedImage["coronal"];
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(x / (rect.width / imgSize[0]));
        sagittal = selectedImage["sagittal"];
        sagittal2 = selectedImage["sagittal"];
      }

      let newPoint = { x: x, y: y };
      if (plane === "axial") {
        newPoint = { x: sagittal2, y: coronal2 };
      } else if (plane === "coronal") {
        newPoint = { x: sagittal2, y: numImages - axial2 };
      } else {
        newPoint = { x: coronal2, y: numImages - axial2 };
      }
      if (bboxPoints[plane].length === 1) {
        const newBboxPoints = bboxPoints[plane].concat(newPoint);

        setBboxPoints((prevBboxPoints) => ({
          ...prevBboxPoints,
          [plane]: newBboxPoints,
        }));
      }
    }
    if (!addStone && measureLength && DrawingLine) {
      setDrawingLine(false);

      let otherPlanes = ["axial", "coronal", "sagittal"];
      otherPlanes = otherPlanes.filter((p) => p !== plane);

      const rect = e.target.getBoundingClientRect();
      const x = parseInt(e.clientX - rect.left);
      const y = parseInt(e.clientY - rect.top);

      let axial, coronal, sagittal, axial2, coronal2, sagittal2;
      if (plane === "axial") {
        axial = selectedImage["axial"];
        axial2 = selectedImage["axial"];
        coronal = Math.round(y / (height[plane] / imgSize[1]));
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(y / (rect.height / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else if (plane === "coronal") {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = selectedImage["coronal"];
        coronal2 = selectedImage["coronal"];
        sagittal = Math.round(x / (width[plane] / imgSize[0]));
        sagittal2 = Math.round(x / (rect.width / imgSize[1]));
      } else {
        axial = numImages - Math.round(y / (height[plane] / numImages));
        axial2 = numImages - Math.round(y / (rect.height / numImages));
        coronal = Math.round(x / (width[plane] / imgSize[0]));
        coronal2 = Math.round(x / (rect.width / imgSize[0]));
        sagittal = selectedImage["sagittal"];
        sagittal2 = selectedImage["sagittal"];
      }

      let newPoint = { x: x, y: y };
      if (plane === "axial") {
        newPoint = { x: sagittal2, y: coronal2 };
      } else if (plane === "coronal") {
        newPoint = { x: sagittal2, y: numImages - axial2 };
      } else {
        newPoint = { x: coronal2, y: numImages - axial2 };
      }
      if (linemeasure[plane].length === 1) {
        const newlinePoints = linemeasure[plane].concat(newPoint);

        setLinemeasure((prevlinemeasure) => ({
          ...prevlinemeasure,
          [plane]: newlinePoints,
        }));

        calculateDistance(
          newlinePoints[0],
          newlinePoints[1],
          dicomMetadata["PixelSpacing"].split(","),
          dicomMetadata["SliceThickness"]
        );
      }
    }
  };

  const calculatePanLimits = (plane) => {
    const container = containerRefs.current[plane].current;
    const containerRect = container.getBoundingClientRect();
    const maxX = containerRect.width - width[plane] * zoom[plane];
    const maxY = containerRect.height - height[plane] * zoom[plane];
    return { maxX, maxY };
  };

  const handleMouseDownCont = (e, plane) => {
    if (e.button === 2) {
      e.preventDefault();
      isPanningRef.current[plane] = true;
    }
  };

  const handleMouseMoveCont = (e, plane) => {
    if (isPanningRef.current[plane]) {
      e.preventDefault();
      const { maxX, maxY } = calculatePanLimits(plane);
      const newPan = { ...pan };
      newPan[plane] = {
        x: Math.min(Math.max(pan[plane].x + e.movementX * 2, maxX), 0),
        y: Math.min(Math.max(pan[plane].y + e.movementY * 2, maxY), 0),
      };
      setPan(newPan);
    }
  };

  const handleMouseUpCont = (plane) => {
    isPanningRef.current[plane] = false;
  };

  const handleWheelCont = (e, plane) => {
    e.preventDefault();
    const delta = e.deltaY;
    const zoomFactor = 0.1;
    const minZoom = 1;
    const maxZoom = 3;
    const newZoom = { ...zoom };
    newZoom[plane] = zoom[plane] + (delta > 0 ? -zoomFactor : zoomFactor);

    if (newZoom[plane] < minZoom) {
      newZoom[plane] = minZoom;
    } else if (newZoom[plane] > maxZoom) {
      newZoom[plane] = maxZoom;
    } else {
      if (delta > 0) {
        const newPan = { ...pan };
        newPan[plane] = { x: 0, y: 0 };
        setPan(newPan);
      }
    }

    setZoom(newZoom);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const calculateDistance = (
    startPoint,
    endPoint,
    pixelSpacing,
    sliceThickness
  ) => {
    if (startPoint.x) {
      const pixelDistanceX = Math.abs(endPoint.x - startPoint.x);
      const pixelDistanceY = Math.abs(endPoint.y - startPoint.y);
      const pixelDistanceZ = sliceThickness;

      const physicalDistanceX = pixelDistanceX * parseFloat(pixelSpacing[0]);
      const physicalDistanceY = pixelDistanceY * parseFloat(pixelSpacing[1]);
      const physicalDistanceZ = pixelDistanceZ * sliceThickness;

      const total3DLength = Math.sqrt(
        Math.pow(physicalDistanceX, 2) +
          Math.pow(physicalDistanceY, 2) +
          Math.pow(physicalDistanceZ, 2)
      );
      // const total3DLength = Math.sqrt(
      //   Math.pow(pixelDistanceX * pixelSpacing[0], 2) +
      //     Math.pow(pixelDistanceY * pixelSpacing[1], 2)
      // );
      setTotalLength(total3DLength.toFixed(2));
      return `${total3DLength.toFixed(2)} mm`;
    } else {
      return null;
    }
  };
  const resetfunction = () => {
    setZoom({
      axial: 1,
      coronal: 1,
      sagittal: 1,
    });
    setPan({
      axial: { x: 0, y: 0 },
      coronal: { x: 0, y: 0 },
      sagittal: { x: 0, y: 0 },
    });
    setLinemeasure({
      axial: [
        { x: null, y: null },
        { x: null, y: null },
      ],
      coronal: [
        { x: null, y: null },
        { x: null, y: null },
      ],
      sagittal: [
        { x: null, y: null },
        { x: null, y: null },
      ],
    });
    setTotalLength(0);
  };
  const intervalRef = React.useRef({
    axial: null,
    coronal: null,
    sagittal: null,
  });
  const incrementValue = (step, max, plane) => {
    // setSelectedImage({ ...selectedImage, axial: val });
    if (plane.toLowerCase() == "axial") {
      setSelectedImage((prevValue) => {
        if (prevValue[plane] + step > max) {
          clearInterval(intervalRef.current[plane]);
          return { ...prevValue, axial: max };
        } else {
          return { ...prevValue, axial: prevValue[plane] + step };
        }
      });
    } else if (plane.toLowerCase() == "coronal") {
      setSelectedImage((prevValue) => {
        if (prevValue[plane] + step > max) {
          clearInterval(intervalRef.current[plane]);
          return { ...prevValue, coronal: max };
        } else {
          return { ...prevValue, coronal: prevValue[plane] + step };
        }
      });
    } else if (plane.toLowerCase() == "sagittal") {
      setSelectedImage((prevValue) => {
        if (prevValue[plane] + step > max) {
          clearInterval(intervalRef.current[plane]);
          return { ...prevValue, sagittal: max };
        } else {
          return { ...prevValue, sagittal: prevValue[plane] + step };
        }
      });
    }
  };
  const startIncrementing = (plane, max) => {
    console.log(plane, max);
    clearInterval(intervalRef.current[plane]);
    intervalRef.current[plane] = setInterval(
      () => incrementValue(cineStep, max, plane),
      1000 / cineFPS
    ); // Increment every 1000ms (1 second)
  };

  const stopIncrementing = (plane) => {
    clearInterval(intervalRef.current[plane]);
  };
  const handleFPSSliderChange = (event, newValue) => {
    setCineFPS(newValue);
  };

  const handleFPSChange = (event) => {
    setCineFPS(event.target.value === "" ? 0 : Number(event.target.value));
  };

  const handleFPSBlur = (e) => {
    if (e.target.value < 0) {
      setCineFPS(0);
    } else if (e.target.value > 90) {
      setCineFPS(90);
    }
  };

  const handleQc = (e) => {
    if (!onVerification) {
      const formData1 = new FormData();
      const username = localStorage.getItem("username");
      formData1.append("job_id", jobId);
      formData1.append("update_key", "current_user");
      formData1.append("update_val", username);
      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");
      axios
        .post(`${baseURLAPI}/job_update`, formData1, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setOnVerification(!onVerification);
            setQcuser(username);
            console.log(res.data.success);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const formData = new FormData();
      formData.append("job_id", jobId);
      formData.append("update_key", "on_verification");
      formData.append("update_val", true);
      axios
        .post(`${baseURLAPI}/job_update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const formData1 = new FormData();
      formData1.append("job_id", jobId);
      formData1.append("update_key", "current_user");
      formData1.append("update_val", "");
      const token = admin
        ? localStorage.getItem(`token-${center}`)
        : localStorage.getItem("token");
      axios
        .post(`${baseURLAPI}/job_update`, formData1, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            setOnVerification(!onVerification);
            setQcuser("");
            console.log(res.data.success);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const formData = new FormData();
      formData.append("job_id", jobId);
      formData.append("update_key", "on_verification");
      formData.append("update_val", false);
      axios
        .post(`${baseURLAPI}/job_update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function valuetext(value) {
    return `${value}`;
  }
  const handleChangex = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setxValue([Math.min(newValue[0], xvalue[1] - minFovDistance), xvalue[1]]);
    } else {
      setxValue([xvalue[0], Math.max(newValue[1], xvalue[0] + minFovDistance)]);
    }
  };
  const handleChangey = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setyValue([Math.min(newValue[0], yvalue[1] - minFovDistance), yvalue[1]]);
    } else {
      setyValue([yvalue[0], Math.max(newValue[1], yvalue[0] + minFovDistance)]);
    }
  };
  const handleChangez = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setzValue([Math.min(newValue[0], zvalue[1] - minFovDistance), zvalue[1]]);
    } else {
      setzValue([zvalue[0], Math.max(newValue[1], zvalue[0] + minFovDistance)]);
    }
  };

  return (
    <div style={styles.root}>
      {/* <div style={styles.options}> */}
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          height: "70px",
          background: "#041c4a",
        }}
      >
        <Button
          onClick={() => {
            setOptionsOpen(!optionsOpen);
            // reset origin
          }}
          style={styles.optionsButton}
        >
          {!optionsOpen ? (
            <KeyboardArrowLeftIcon style={{ fill: "white" }} />
          ) : (
            <KeyboardArrowRightIcon style={{ fill: "white" }} />
          )}
        </Button>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "70%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "70%",
            }}
          >
            <Tooltip title="Show Cropped Images">
              <div
                className={
                  showCropped ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  setShowCropped(!showCropped);
                  localStorage.setItem("showCropped", !showCropped);
                  window.location.reload();
                }}
              >
                <TbCrop style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Show Report">
              <div
                className={
                  reportModalOpen ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  setReportConfig(config)
                  setReportModalOpen(true);
                }}
              >
                <TbFileTypePdf style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Feedback">
              <div
                className={
                  feedbackModalOpen ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  axios
                    .get(`${baseURLAPI}/feedback?job_id=${jobId}`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then((res) => {
                      console.log("feedbacks", res);
                      setFeedbacks(res.data.feedback);
                      setFeedbackModalOpen(true);
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
              >
                <TbMessage />
              </div>
            </Tooltip>
            <Tooltip title="configs and Reports">
              <div
                className={
                  configModalOpen ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  getConfigs(jobId, center);
                }}
              >
                <TbReportMedical style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Probe">
              <div
                className={probeOn ? "switchOptionSelected" : "switchOption"}
                onClick={() => {
                  setProbeOn(!probeOn);
                }}
              >
                <TbZoomCode style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Crosshair">
              <div
                className={
                  crossHairOn ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  setCrossHairOn(!crossHairOn);
                }}
              >
                <TbCrosshair style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Reset Zoom and Measurement">
              <div
                className={"switchOption"}
                onClick={() => {
                  resetfunction();
                }}
              >
                <TbZoomReset style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Zoom and Pan">
              <div
                className={
                  activeTool == "zoom" ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  activeTool == "zoom"
                    ? setActiveTool(null)
                    : setActiveTool("zoom");
                }}
              >
                <TbZoomPan style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Measurement">
              <div
                className={
                  measureLength ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  setMeasureLength(!measureLength);
                }}
              >
                <TbRulerMeasure style={{ width: "20px", height: "20px" }} />
              </div>
            </Tooltip>
            <Tooltip title="Erase Mask">
              <div
                className={
                  editMaskOptions.erase
                    ? "switchOptionSelected"
                    : "switchOption"
                }
                onClick={() => {
                  setEditMaskOptions({
                    ...editMaskOptions,
                    erase: !editMaskOptions.erase,
                    draw: false,
                    drawPolygon: false,
                  });
                }}
              >
                {!editMaskOptions.erase ? (
                  <TbEraser style={{ width: "20px", height: "20px" }} />
                ) : (
                  <TbEraserOff style={{ width: "20px", height: "20px" }} />
                )}
              </div>
            </Tooltip>
            <Tooltip title="Draw Mask">
              <div
                className={
                  editMaskOptions.draw ? "switchOptionSelected" : "switchOption"
                }
                onClick={() => {
                  setEditMaskOptions({
                    ...editMaskOptions,
                    draw: !editMaskOptions.draw,
                    erase: false,
                    drawPolygon: false,
                  });
                }}
              >
                {!editMaskOptions.draw ? (
                  <TbPencil style={{ width: "20px", height: "20px" }} />
                ) : (
                  <TbPencilOff style={{ width: "20px", height: "20px" }} />
                )}
              </div>
            </Tooltip>
            <Tooltip title="Draw Polygon">
              <div
                className={
                  editMaskOptions.drawPolygon
                    ? "switchOptionSelected"
                    : "switchOption"
                }
                onClick={() => {
                  setEditMaskOptions({
                    ...editMaskOptions,
                    drawPolygon: !editMaskOptions.drawPolygon,
                    erase: false,
                    draw: false,
                  });
                  setPolygonPoints([]);
                }}
              >
                {!editMaskOptions.drawPolygon ? (
                  <TbPolygon style={{ width: "20px", height: "20px" }} />
                ) : (
                  <TbPolygonOff style={{ width: "20px", height: "20px" }} />
                )}
              </div>
            </Tooltip>
            <Tooltip
              title={
                <Box
                  sx={{
                    width: 250,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography id="input-slider" gutterBottom>
                    Cine FPS
                  </Typography>
                  <PrettoSlider
                    value={typeof cineFPS === "number" ? cineFPS : 1}
                    min={1}
                    max={15}
                    onChange={handleFPSSliderChange}
                    aria-labelledby="input-slider"
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                  />
                  <Input
                    value={cineFPS}
                    size="small"
                    onChange={handleFPSChange}
                    sx={{ color: "#fff", caretColor: "transparent" }}
                    onBlur={handleFPSBlur}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 15,
                      type: "number",
                      "aria-labelledby": "input-slider",
                      onKeyDown: (event) => {
                        event.preventDefault();
                      },
                    }}
                  />
                </Box>
              }
            >
              <div className={"switchOption"}>
                <TbKeyframes />
              </div>
            </Tooltip>
            <Tooltip
              title={
                <Box
                  sx={{
                    width: 280,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography id="input-slider" gutterBottom>
                    Window W&L Presets
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        width: 45,
                        height: 45,
                        marginInline: "4px",
                        padding: "3px",
                        background: "grey",
                        borderRadius: "4px",
                      }}
                      title="CT-Abdomen"
                    >
                      <img
                        onClick={() => {
                          setWindowVals([40, 350]);
                        }}
                        width={"100%"}
                        height={"100%"}
                        src="/dashboard/assets/annotate/WindowLevelPreset-Abdomen.png"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 45,
                        height: 45,
                        marginInline: "4px",
                        padding: "3px",
                        background: "grey",
                        borderRadius: "4px",
                      }}
                      title="CT-Brain"
                    >
                      <img
                        onClick={() => {
                          setWindowVals([50, 100]);
                        }}
                        width={"100%"}
                        height={"100%"}
                        src="/dashboard/assets/annotate/WindowLevelPreset-Brain.png"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 45,
                        height: 45,
                        marginInline: "4px",
                        padding: "3px",
                        background: "grey",
                        borderRadius: "4px",
                      }}
                      title="CT-air"
                    >
                      <img
                        onClick={() => {
                          setWindowVals([-426, 1000]);
                        }}
                        width={"100%"}
                        height={"100%"}
                        src="/dashboard/assets/annotate/WindowLevelPreset-CT-air.png"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 45,
                        height: 45,
                        marginInline: "4px",
                        padding: "3px",
                        background: "grey",
                        borderRadius: "4px",
                      }}
                      title="CT-Bone"
                    >
                      <img
                        onClick={() => {
                          setWindowVals([400, 1000]);
                        }}
                        width={"100%"}
                        height={"100%"}
                        src="/dashboard/assets/annotate/WindowLevelPreset-CT-bone.png"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: 45,
                        height: 45,
                        marginInline: "4px",
                        padding: "3px",
                        background: "grey",
                        borderRadius: "4px",
                      }}
                      title="CT-Lung"
                    >
                      <img
                        onClick={() => {
                          setWindowVals([-500, 1400]);
                        }}
                        width={"100%"}
                        height={"100%"}
                        src="/dashboard/assets/annotate/WindowLevelPreset-Lung.png"
                      />
                    </Box>
                  </Box>
                  <Typography id="input-slider" gutterBottom>
                    Window W&L Inputs
                  </Typography>
                  <TextField
                    id="windowLevel"
                    label="level"
                    type="number"
                    value={windowVals[0]}
                    onChange={(e) =>
                      setWindowVals([parseInt(e.target.value), windowVals[1]])
                    }
                    inputProps={{
                      style: { color: "white" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "white" },
                    }}
                    variant="outlined"
                    size="small"
                    style={{ width: "90%", marginTop: "10px" }}
                  />

                  <TextField
                    id="windowWidth"
                    label="width"
                    type="number"
                    value={windowVals[1]}
                    onChange={(e) =>
                      setWindowVals([windowVals[0], parseInt(e.target.value)])
                    }
                    inputProps={{
                      style: { color: "white" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "white" },
                    }}
                    variant="outlined"
                    size="small"
                    style={{ width: "90%", marginTop: "10px" }}
                  />
                </Box>
              }
            >
              <div className={"switchOption"}>
                <TbPhotoCog />
              </div>
            </Tooltip>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "30%",
            }}
          >
            <Select
              value={config}
              style={styles.configsSelect}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(228, 219, 233, 0.25)",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#049c77 !important",
                },
              }}
              onChange={(e) => {
                // setStones(e.target.value);
                updateConfigs({ stone: e.target.value, i: e.target.key });
              }}
            >
              {Object.keys(configs).map((config, i) => {
                return (
                  <MenuItem key={i} value={config}>
                    {config}
                  </MenuItem>
                );
              })}
            </Select>
            <Tooltip title="Save Config">
              <div
                className={"switchOption"}
                onClick={onConfigSave}
                disabled={!loaded || saving}
                style={{
                  pointerEvents: (!loaded || saving) && "none",
                  opacity: (!loaded || saving) && 0.7,
                }}
              >
                {!saving ? (
                  <TbDatabasePlus style={{ width: "20px", height: "20px" }} />
                ) : (
                  <LoadingComponent />
                )}
              </div>
            </Tooltip>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#90cdf4",
              width: "10%",
            }}
          >
            <IOSSwitch
              sx={{ margin: "7px" }}
              checked={onVerification}
              onChange={(e) => handleQc(e)}
            />
            {onVerification ? "on" : "off"} QC{" "}
            {qcuser ? qcuser.split("@")[0] : ""}
          </div>
        </div>
        <Button
          onClick={() => {
            setstoneNav(!stoneNav);
            // reset origin
          }}
          style={styles.optionsButton}
        >
          {stoneNav ? (
            <KeyboardArrowLeftIcon style={{ fill: "white" }} />
          ) : (
            <KeyboardArrowRightIcon style={{ fill: "white" }} />
          )}
        </Button>
      </div>

      <div style={styles.body} onContextMenu={(e) => e.preventDefault()}>
        <div style={styles.optionsDrawer(optionsOpen)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#90cdf4",
            }}
          >
            <IOSSwitch
              sx={{ margin: "7px" }}
              checked={showMoreOptions}
              onChange={() => setShowMoreOptions(!showMoreOptions)}
            />
            {showMoreOptions ? "Hide" : "Show"} more options
          </div>

          {showMoreOptions ? (
            <div style={styles.optionsDrawerContent}> 
              Config:
              <Select
                value={config}
                style={styles.configsSelect}
                onChange={(e) => {
                  // setStones(e.target.value);
                  updateConfigs({ stone: e.target.value, i: e.target.key });
                }}
              >
                {Object.keys(configs).map((config, i) => {
                  return (
                    <MenuItem key={i} value={config}>
                      {config}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  let url = `${baseURLAPI}/run_horseshoe`;
                  runAgain(url);
                }}
                // disabled={!loaded}
              >
                Rerun with horseshoe
              </Button> */}
              {/* <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // let url = `${baseURLAPI}/run_diseased`;
                  // runAgain(url);
                  setFovModalOpen(true);
                }}
                // disabled={!loaded}
              >
                Rerun with diseased
              </Button> */}
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // first select file from file system by opening dialog
                  // const fileInput = document.createElement("input");
                  // fileInput.type = "file";
                  // fileInput.addEventListener("change", (event) => {
                  //   const selectedFile = event.target.files[0];
                  //   const url = `${baseURLAPI}/run_with`;
                  //   runAgain(url, selectedFile, "postprocessed2");
                  //   // Add your code to handle the selected file here
                  // });
                  // fileInput.click();
                  // fileInput.remove();
                  setMaskModalOpen(true);
                }}
                // disabled={!loaded}
              >
                Rerun with both Masks
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // first select file from file system by opening dialog
                  const fileInput = document.createElement("input");
                  fileInput.type = "file";
                  fileInput.addEventListener("change", (event) => {
                    const selectedFile = event.target.files[0];
                    const url = `${baseURLAPI}/run_with`;
                    runAgain(url, selectedFile, "postprocessed2");
                    // Add your code to handle the selected file here
                  });
                  fileInput.click();
                  fileInput.remove();
                }}
                // disabled={!loaded}
              >
                Rerun with PostProcessed
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // first select file from file system by opening dialog
                  const fileInput = document.createElement("input");
                  fileInput.type = "file";
                  fileInput.addEventListener("change", (event) => {
                    const selectedFile = event.target.files[0];
                    const url = `${baseURLAPI}/run_with`;
                    runAgain(url, selectedFile, "stone_mask");
                    // Add your code to handle the selected file here
                  });
                  fileInput.click();
                  fileInput.remove();
                }}
                // disabled={!loaded}
              >
                Rerun with StoneMask
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  const url = `${baseURLAPI}/download_img?job_id=${jobId}&config_name=${config}`;
                  axios
                    .get(url, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                      responseType: "blob",
                    })
                    .then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "img.nii.gz");
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
              >
                Download Image
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  const url = `${baseURLAPI}/download_mask?job_id=${jobId}&config_name=${config}&mask=kub`;
                  axios
                    .get(url, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                      responseType: "blob",
                    })
                    .then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "kub.nii.gz");
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
              >
                Download KUBMask
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  const token = admin
                    ? localStorage.getItem(`token-${center}`)
                    : localStorage.getItem("token");
                  const url = `${baseURLAPI}/download_mask?job_id=${jobId}&config_name=${config}&mask=stones`;
                  axios
                    .get(url, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                      responseType: "blob",
                    })
                    .then((res) => {
                      const url = window.URL.createObjectURL(
                        new Blob([res.data])
                      );
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", "stones.nii.gz");
                      document.body.appendChild(link);
                      link.click();
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
              >
                Download StoneMask
              </Button>
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // first select file from file system by opening dialog
                  const fileInput = document.createElement("input");
                  fileInput.type = "file";
                  fileInput.addEventListener("change", (event) => {
                    const selectedFile = event.target.files[0];
                    const url = `${baseURLAPI}/run_with`;
                    runAgain(url, selectedFile, "postprocessed2");
                    // Add your code to handle the selected file here
                  });
                  fileInput.click();
                  fileInput.remove();
                }}
                // disabled={!loaded}
              >
                Upload QC mask
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <table
                  style={{
                    borderCollapse: "collapse",
                    marginBlock: "10px",
                    width: "100%",
                  }}
                >
                  <tbody>
                    {Object.entries(erpVolumes).map(([name, volume], i) => {
                      return (
                        <tr key={i}>
                          <td
                            style={{
                              padding: "5px 10px",
                              fontSize: "12pt",
                              fontWeight: "600",
                              border: "1px solid #fff",
                            }}
                          >
                            {name}
                          </td>
                          <td
                            style={{
                              padding: "5px 10px",
                              fontSize: "12pt",
                              border: "1px solid #fff",
                            }}
                          >
                            {Math.round(volume)} mm<sup>3</sup>
                          </td>
                        </tr>
                      );
                    })}
                    {smtvalue["dog"] && (
                      <tr>
                        <td
                          style={{
                            padding: "5px 10px",
                            fontSize: "12pt",
                            fontWeight: "600",
                            border: "1px solid #fff",
                          }}
                        >
                          Smoothness Factor
                        </td>
                        <td
                          style={{
                            padding: "5px 10px",
                            fontSize: "12pt",
                            border: "1px solid #fff",
                          }}
                        >
                          {smtvalue["dog"].toFixed(2)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p
                    style={{
                      marginRight: "10px",
                      fontSize: "12pt",
                      fontWeight: "600",
                    }}
                  >
                    Num visitors
                  </p>
                  <p style={{ marginRight: "10px", fontSize: "12pt" }}>
                    {numVisitors}
                  </p>
                </div>
              </div>
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: 'blue'
              }}>
                <Button onClick={() => setLogModalOpen(true)}> Logs <DescriptionIcon /> </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {loaded ? (
          <div
            className="Imagewrapper"
            style={fullscreen ? styles.container : styles.container2}
          >
            {!fullscreen || fullscreen === "axial" ? (
              <div
                style={styles.imgWrapper(
                  fullscreen === "axial",
                  dimensions.width
                )}
              >
                {/* <div style={{ color: "white" }}>
                  {selectedImage["axial"]}/{numImages}
                </div> */}
                <FullScreenButton
                  onClick={() => {
                    handleFullscreenClick("axial");
                  }}
                />
                <div
                  ref={containerRefs.current["axial"]}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseDown={(e) => {
                    activeTool && handleMouseDownCont(e, "axial");
                  }}
                  onMouseMove={(e) => {
                    activeTool && handleMouseMoveCont(e, "axial");
                  }}
                  onMouseUp={() => {
                    activeTool && handleMouseUpCont("axial");
                  }}
                  onWheel={(e) => {
                    activeTool == "zoom" && handleWheelCont(e, "axial");
                  }}
                  onContextMenu={handleContextMenu}
                >
                  <div
                    style={{
                      transform: `scale(${zoom["axial"]})`,
                      transformOrigin: "top left",
                      cursor:
                        zoom["axial"] === 1
                          ? "default"
                          : isPanningRef.current["axial"]
                          ? "grabbing"
                          : "grab",
                      position: "absolute",
                      left: `${pan["axial"].x}px`,
                      top: `${pan["axial"].y}px`,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Tooltip
                      title={
                        probeOn ? (
                          <div>
                            HU Value: {probeValue}
                            <br />
                            {probePosition}
                          </div>
                        ) : null
                      }
                      placement="top"
                      followCursor
                      arrow
                      disableHoverListener={probeOn ? false : true}
                    >
                      <img
                        onMouseEnter={() => handleMouseEnter()}
                        id="myImg"
                        ref={(node) => {
                          axialImgRef = node;
                        }}
                        width={"100%"}
                        height={"100%"}
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => handleMouseDown(e, "axial")}
                        onMouseUp={(e) => handleMouseUp(e, "axial")}
                        onWheel={(e) => {
                          handleWheel(e, "axial");
                        }}
                        onMouseMove={(e, plane = "axial") => {
                          if (probeOn) {
                            const rect = e.target.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;

                            const sagittal = Math.round(
                              x / (rect.width / imgSize[1])
                            );
                            const coronal = Math.round(
                              y / (rect.height / imgSize[0])
                            );
                            const axial = selectedImage["axial"];
                            const val =
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2
                              ] +
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2 +
                                  1
                              ] *
                                256 -
                              8192;
                            setProbeValue(val);
                            setProbePosition(
                              [sagittal, coronal, axial].join(", ")
                            );
                          }
                          if (zoomv) {
                            handleZoom(e, "axial");
                          }
                          if (panOn) {
                            handlePan(e, "axial");
                          }
                          if (editMaskOptions.erase) {
                            handleDrawErase(e, "axial", "erase", false);
                          } else if (editMaskOptions.draw) {
                            handleDrawErase(e, "axial", "draw", false);
                          }
                          if (
                            addStone &&
                            addStoneMethod === "Bbox" &&
                            drawingbbox
                          ) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);

                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = { x: sagittal2, y: coronal2 };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = { x: coronal2, y: numImages - axial2 };
                            }
                            if (bboxPoints[plane]) {
                              const newBboxPoints = [
                                bboxPoints[plane][0],
                                newPoint,
                              ];
                              setBboxPoints((prevBboxPoints) => ({
                                ...prevBboxPoints,
                                [plane]: newBboxPoints,
                              }));
                            }
                          }
                          if (!addStone && measureLength && DrawingLine) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);

                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = { x: sagittal2, y: coronal2 };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = { x: coronal2, y: numImages - axial2 };
                            }
                            if (linemeasure[plane]) {
                              const newlinePoints = [
                                linemeasure[plane][0],
                                newPoint,
                              ];
                              setLinemeasure((prevlinePoints) => ({
                                ...prevlinePoints,
                                [plane]: newlinePoints,
                              }));
                              calculateDistance(
                                linemeasure[plane][0],
                                newPoint,
                                dicomMetadata["PixelSpacing"].split(","),
                                dicomMetadata["SliceThickness"]
                              );
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          setPopperOpen(false);
                          handleMouseLeave();
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                            const circle = document.getElementById("circle");
                            if (circle) {
                              circle.remove();
                            }
                          }
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                          } else if (editMaskOptions.drawPolygon) {
                            handleDrawErasePoints(e, "axial", "draw");
                            setPolygonPoints([]);
                          }
                        }}
                        onClick={(e) => {
                          handleOnClick(e, "axial");
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1, width: "100%" }}
                  alignItems="center"
                >
                  {pauseCine["axial"] ? (
                    <TbPlayerPlay
                      onClick={() => {
                        startIncrementing("axial", numImages - 1);
                        setPauseCine({ ...pauseCine, axial: false });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  ) : (
                    <TbPlayerPause
                      onClick={() => {
                        stopIncrementing("axial");
                        setPauseCine({ ...pauseCine, axial: true });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  )}

                  <Slider
                    style={styles.slider}
                    value={selectedImage["axial"]}
                    min={0}
                    max={numImages - 1}
                    onChange={(e, val) => {
                      setPlane("axial");
                      setSelectedImage({ ...selectedImage, axial: val });
                    }}
                  />
                  <div style={{ color: "white" }}>
                    {selectedImage["axial"]}/{numImages}
                  </div>
                </Stack>
              </div>
            ) : null}
            {!fullscreen || fullscreen === "coronal" ? (
              <div
                style={styles.imgWrapper(
                  fullscreen === "coronal",
                  dimensions.width
                )}
              >
                {/* <div style={{ color: "white" }}>
                  {selectedImage["coronal"]}/{imgSize[0]}
                </div> */}
                <FullScreenButton
                  onClick={() => {
                    handleFullscreenClick("coronal");
                  }}
                />
                <div
                  ref={containerRefs.current["coronal"]}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseDown={(e) => {
                    activeTool && handleMouseDownCont(e, "coronal");
                  }}
                  onMouseMove={(e) => {
                    activeTool && handleMouseMoveCont(e, "coronal");
                  }}
                  onMouseUp={() => {
                    activeTool && handleMouseUpCont("coronal");
                  }}
                  onWheel={(e) => {
                    activeTool == "zoom" && handleWheelCont(e, "coronal");
                  }}
                  onContextMenu={handleContextMenu}
                >
                  <div
                    style={{
                      transform: `scale(${zoom["coronal"]})`,
                      transformOrigin: "top left",
                      cursor:
                        zoom["coronal"] === 1
                          ? "default"
                          : isPanningRef.current["coronal"]
                          ? "grabbing"
                          : "grab",
                      position: "absolute",
                      left: `${pan["coronal"].x}px`,
                      top: `${pan["coronal"].y}px`,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Tooltip
                      title={
                        probeOn ? (
                          <div>
                            HU Value: {probeValue}
                            <br />
                            {probePosition}
                          </div>
                        ) : null
                      }
                      placement="top"
                      followCursor
                      arrow
                      disableHoverListener={probeOn ? false : true}
                    >
                      <img
                        id="myImg"
                        ref={(node) => {
                          coronalImgRef = node;
                        }}
                        width={"100%"}
                        height={"100%"}
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => handleMouseDown(e, "coronal")}
                        onMouseUp={(e) => handleMouseUp(e, "coronal")}
                        onWheel={(e) => {
                          handleWheel(e, "coronal");
                        }}
                        onClick={(e) => {
                          handleOnClick(e, "coronal");
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                          } else if (editMaskOptions.drawPolygon) {
                            handleDrawErasePoints(e, "coronal", "draw");
                            setPolygonPoints([]);
                          }
                        }}
                        onMouseMove={(e, plane = "coronal") => {
                          if (probeOn) {
                            const rect = e.target.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;
                            const sagittal = Math.round(
                              (x / rect.width) * imgSize[1]
                            );
                            const coronal = selectedImage["coronal"];
                            const axial =
                              numImages -
                              // Math.round((y / height["coronal"]) * numImages);
                              Math.round((y / rect.height) * numImages);
                            const val =
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2
                              ] +
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2 +
                                  1
                              ] *
                                256 -
                              8192;
                            setProbeValue(val);
                            setProbePosition(
                              [sagittal, coronal, axial].join(", ")
                            );
                          }
                          if (zoomv) {
                            handleZoom(e, "coronal");
                          }
                          if (editMaskOptions.erase) {
                            handleDrawErase(e, "coronal", "erase", false);
                          } else if (editMaskOptions.draw) {
                            handleDrawErase(e, "coronal", "draw", false);
                          }
                          if (
                            addStone &&
                            addStoneMethod === "Bbox" &&
                            drawingbbox
                          ) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);

                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = { x: sagittal2, y: coronal2 };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = { x: coronal2, y: numImages - axial2 };
                            }
                            if (bboxPoints[plane]) {
                              const newBboxPoints = [
                                bboxPoints[plane][0],
                                newPoint,
                              ];
                              setBboxPoints((prevBboxPoints) => ({
                                ...prevBboxPoints,
                                [plane]: newBboxPoints,
                              }));
                            }
                          }
                          if (!addStone && measureLength && DrawingLine) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);

                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = {
                                x: sagittal2,
                                y: coronal2,
                              };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = {
                                x: coronal2,
                                y: numImages - axial2,
                              };
                            }
                            if (linemeasure[plane]) {
                              const newlinePoints = [
                                linemeasure[plane][0],
                                newPoint,
                              ];
                              setLinemeasure((prevlinePoints) => ({
                                ...prevlinePoints,
                                [plane]: newlinePoints,
                              }));
                              calculateDistance(
                                linemeasure[plane][0],
                                newPoint,
                                dicomMetadata["PixelSpacing"].split(","),
                                dicomMetadata["SliceThickness"]
                              );
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          handleMouseLeave();
                          // if (probeOn) {
                          //     setProbeOn(false);
                          // }
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                            const circle = document.getElementById("circle");
                            if (circle) {
                              circle.remove();
                            }
                          }
                        }}
                        onMouseEnter={() => handleMouseEnter()}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1, width: "100%" }}
                  alignItems="center"
                >
                  {pauseCine["coronal"] ? (
                    <TbPlayerPlay
                      onClick={() => {
                        startIncrementing("coronal", imgSize[0]);
                        setPauseCine({ ...pauseCine, coronal: false });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  ) : (
                    <TbPlayerPause
                      onClick={() => {
                        stopIncrementing("coronal");
                        setPauseCine({ ...pauseCine, coronal: true });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  )}
                  <Slider
                    style={styles.slider}
                    value={selectedImage["coronal"]}
                    min={0}
                    max={imgSize[0]}
                    onChange={(e, val) => {
                      setPlane("coronal");
                      setSelectedImage({ ...selectedImage, coronal: val });
                    }}
                  />
                  <div style={{ color: "white" }}>
                    {selectedImage["coronal"]}/{imgSize[0]}
                  </div>
                </Stack>
              </div>
            ) : null}
            {!fullscreen || fullscreen === "sagittal" ? (
              <div
                style={styles.imgWrapper(
                  fullscreen === "sagittal",
                  dimensions.width
                )}
              >
                {/* <div style={{ color: "white" }}>
                  {selectedImage["sagittal"]}/{imgSize[1]}
                </div> */}
                <FullScreenButton
                  onClick={() => {
                    handleFullscreenClick("sagittal");
                  }}
                />
                <div
                  ref={containerRefs.current["sagittal"]}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseDown={(e) => {
                    activeTool && handleMouseDownCont(e, "sagittal");
                  }}
                  onMouseMove={(e) => {
                    activeTool && handleMouseMoveCont(e, "sagittal");
                  }}
                  onMouseUp={() => {
                    activeTool && handleMouseUpCont("sagittal");
                  }}
                  onWheel={(e) => {
                    activeTool == "zoom" && handleWheelCont(e, "sagittal");
                  }}
                  onContextMenu={handleContextMenu}
                >
                  <div
                    style={{
                      transform: `scale(${zoom["sagittal"]})`,
                      transformOrigin: "top left",
                      cursor:
                        zoom["sagittal"] === 1
                          ? "default"
                          : isPanningRef.current["sagittal"]
                          ? "grabbing"
                          : "grab",
                      position: "absolute",
                      left: `${pan["sagittal"].x}px`,
                      top: `${pan["sagittal"].y}px`,
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Tooltip
                      title={
                        probeOn ? (
                          <div>
                            HU Value: {probeValue}
                            <br />
                            {probePosition}
                          </div>
                        ) : null
                      }
                      placement="top"
                      followCursor
                      arrow
                      disableHoverListener={probeOn ? false : true}
                    >
                      <img
                        id="myImg"
                        ref={(node) => {
                          sagittalImgRef = node;
                        }}
                        width={"100%"}
                        height={"100%"}
                        onDragStart={(e) => {
                          e.preventDefault();
                        }}
                        onMouseDown={(e) => handleMouseDown(e, "sagittal")}
                        onMouseUp={(e) => handleMouseUp(e, "sagittal")}
                        onWheel={(e) => {
                          handleWheel(e, "sagittal");
                        }}
                        onClick={(e) => {
                          handleOnClick(e, "sagittal");
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                          } else if (editMaskOptions.drawPolygon) {
                            handleDrawErasePoints(e, "sagittal", "draw");
                            setPolygonPoints([]);
                          }
                        }}
                        onMouseMove={(e, plane = "sagittal") => {
                          if (probeOn) {
                            const rect = e.target.getBoundingClientRect();
                            const x = e.clientX - rect.left;
                            const y = e.clientY - rect.top;
                            const sagittal = selectedImage["sagittal"];
                            const coronal = Math.round(
                              // (x / width["sagittal"]) * imgSize[1]
                              (x / rect.width) * imgSize[0]
                            );
                            const axial =
                              numImages -
                              // Math.round((y / height["sagittal"]) * numImages);
                              Math.round((y / rect.height) * numImages);
                            const val =
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2
                              ] +
                              imageData3D[
                                (axial * imgSize[0] * imgSize[1] +
                                  coronal * imgSize[1] +
                                  sagittal) *
                                  2 +
                                  1
                              ] *
                                256 -
                              8192;
                            setProbeValue(val);
                            setProbePosition(
                              [sagittal, coronal, axial].join(", ")
                            );
                          }
                          if (zoomv) {
                            handleZoom(e, "sagittal");
                          }
                          if (editMaskOptions.erase) {
                            handleDrawErase(e, "sagittal", "erase", false);
                          } else if (editMaskOptions.draw) {
                            handleDrawErase(e, "sagittal", "draw", false);
                          }
                          if (
                            addStone &&
                            addStoneMethod === "Bbox" &&
                            drawingbbox
                          ) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);
                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = {
                                x: sagittal2,
                                y: coronal2,
                              };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = {
                                x: coronal2,
                                y: numImages - axial2,
                              };
                            }
                            if (bboxPoints[plane]) {
                              const newBboxPoints = [
                                bboxPoints[plane][0],
                                newPoint,
                              ];
                              setBboxPoints((prevBboxPoints) => ({
                                ...prevBboxPoints,
                                [plane]: newBboxPoints,
                              }));
                            }
                          }
                          if (!addStone && measureLength && DrawingLine) {
                            let otherPlanes = ["axial", "coronal", "sagittal"];
                            otherPlanes = otherPlanes.filter(
                              (p) => p !== plane
                            );

                            const rect = e.target.getBoundingClientRect();
                            const x = parseInt(e.clientX - rect.left);
                            const y = parseInt(e.clientY - rect.top);

                            let axial,
                              coronal,
                              sagittal,
                              axial2,
                              coronal2,
                              sagittal2;
                            if (plane === "axial") {
                              axial = selectedImage["axial"];
                              axial2 = selectedImage["axial"];
                              coronal = Math.round(
                                y / (height[plane] / imgSize[1])
                              );
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                y / (rect.height / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else if (plane === "coronal") {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = selectedImage["coronal"];
                              coronal2 = selectedImage["coronal"];
                              sagittal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              sagittal2 = Math.round(
                                x / (rect.width / imgSize[1])
                              );
                            } else {
                              axial =
                                numImages -
                                Math.round(y / (height[plane] / numImages));
                              axial2 =
                                numImages -
                                Math.round(y / (rect.height / numImages));
                              coronal = Math.round(
                                x / (width[plane] / imgSize[0])
                              );
                              coronal2 = Math.round(
                                x / (rect.width / imgSize[0])
                              );
                              sagittal = selectedImage["sagittal"];
                              sagittal2 = selectedImage["sagittal"];
                            }

                            let newPoint = { x: x, y: y };
                            if (plane === "axial") {
                              newPoint = {
                                x: sagittal2,
                                y: coronal2,
                              };
                            } else if (plane === "coronal") {
                              newPoint = {
                                x: sagittal2,
                                y: numImages - axial2,
                              };
                            } else {
                              newPoint = {
                                x: coronal2,
                                y: numImages - axial2,
                              };
                            }
                            if (linemeasure[plane]) {
                              const newlinePoints = [
                                linemeasure[plane][0],
                                newPoint,
                              ];
                              setLinemeasure((prevlinePoints) => ({
                                ...prevlinePoints,
                                [plane]: newlinePoints,
                              }));
                              calculateDistance(
                                linemeasure[plane][0],
                                newPoint,
                                dicomMetadata["PixelSpacing"].split(","),
                                dicomMetadata["SliceThickness"]
                              );
                            }
                          }
                        }}
                        onMouseLeave={() => {
                          handleMouseLeave();
                          // if (probeOn) {
                          //     setProbeOn(false);
                          // }
                          if (editMaskOptions.erase || editMaskOptions.draw) {
                            setEditMaskOptions({
                              ...editMaskOptions,
                              dragging: false,
                            });
                            const circle = document.getElementById("circle");
                            if (circle) {
                              circle.remove();
                            }
                          }
                        }}
                        onMouseEnter={() => handleMouseEnter()}
                      />
                    </Tooltip>
                  </div>
                </div>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ mb: 1, width: "100%" }}
                  alignItems="center"
                >
                  {pauseCine["sagittal"] ? (
                    <TbPlayerPlay
                      onClick={() => {
                        startIncrementing("sagittal", imgSize[1]);
                        setPauseCine({ ...pauseCine, sagittal: false });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  ) : (
                    <TbPlayerPause
                      onClick={() => {
                        stopIncrementing("sagittal");
                        setPauseCine({ ...pauseCine, sagittal: true });
                      }}
                      color="#049c77"
                      style={{
                        background: "#fff",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        border: "1px solid #049c77",
                      }}
                    />
                  )}
                  <Slider
                    style={styles.slider}
                    value={selectedImage["sagittal"]}
                    min={0}
                    max={imgSize[1]}
                    onChange={(e, val) => {
                      setPlane("sagittal");
                      setSelectedImage({ ...selectedImage, sagittal: val });
                    }}
                  />
                  <div style={{ color: "white" }}>
                    {selectedImage["sagittal"]}/{imgSize[1]}
                  </div>
                </Stack>
              </div>
            ) : null}
            {/* { !fullscreen || fullscreen === '3D' ? */}
            <div
              style={styles.vtkContainer(
                loaded && (!fullscreen || fullscreen === "3D"),
                fullscreen === "3D"
              )}
            >
              <FullScreenButton
                onClick={() => {
                  handleFullscreenClick("3D");
                }}
              />

              <div
                id="vtkContainer"
                style={{ width: "100%", height: "100%" }}
                ref={vtkContainerRef}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {!fullscreen || fullscreen === "axial" ? (
              <div
                style={styles.imgWrapper(
                  fullscreen === "axial",
                  dimensions.width
                )}
              >
                <div style={{ color: "white" }}>
                  {selectedImage["axial"]}/{numLoaded}
                </div>
                <FullScreenButton
                  onClick={() => {
                    handleFullscreenClick("axial");
                  }}
                />
                <img
                  id="myImg"
                  ref={(node) => {
                    axialImgRef = node;
                  }}
                  width={window.innerHeight * 0.7}
                  height={window.innerHeight * 0.7}
                  onDragStart={(e) => {
                    e.preventDefault();
                  }}
                  onWheel={(e) => {
                    handleWheel(e, "axial");
                  }}
                  onMouseMove={(e) => {
                    if (probeOn) {
                      const rect = e.target.getBoundingClientRect();
                      const x = e.clientX - rect.left;
                      const y = e.clientY - rect.top;
                      const sagittal = Math.round(
                        // (x / width["axial"]) * imgSize[0]
                        (x / rect.width) * imgSize[1]
                      );
                      const coronal = Math.round(
                        // (y / height["axial"]) * imgSize[1]
                        (y / rect.height) * imgSize[0]
                      );
                      const axial = selectedImage["axial"];
                      const val =
                        imageData3D[
                          (axial * imgSize[0] * imgSize[1] +
                            coronal * imgSize[1] +
                            sagittal) *
                            2
                        ] +
                        imageData3D[
                          (axial * imgSize[0] * imgSize[1] +
                            coronal * imgSize[1] +
                            sagittal) *
                            2 +
                            1
                        ] *
                          256 -
                        8192;
                      setProbeValue(val);
                      setProbePosition([sagittal, coronal, axial].join(", "));
                    }
                    if (zoomv) {
                      handleZoom(e, "axial");
                    }
                    if (panOn) {
                      handlePan(e, "axial");
                    }
                  }}
                  onMouseLeave={() => {
                    setPopperOpen(false);
                    handleMouseLeave();
                    // if (probeOn) {
                    //     setProbeOn(false);
                    // }
                  }}
                  onClick={(e) => {
                    handleOnClick(e, "axial");
                  }}
                  onMouseEnter={() => handleMouseEnter()}
                />
                <Slider
                  style={styles.slider}
                  value={selectedImage["axial"]}
                  min={0}
                  max={numLoaded}
                  onChange={(e, val) => {
                    setPlane("axial");
                    setSelectedImage({ ...selectedImage, axial: val });
                  }}
                />

                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    value={Math.round((numLoaded / numImages) * 100)}
                    variant="determinate"
                    color="primary"
                    size={50}
                  ></CircularProgress>
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="white"
                    >{`${Math.round(
                      (numLoaded / numImages) * 100
                    )}%`}</Typography>
                  </Box>
                </Box>
              </div>
            ) : null}
          </div>
        )}
        <div style={styles.optionsDrawer(stoneNav)}>
          Model:
          <Select
            // fullWidth
            label="Model"
            style={styles.configsSelect}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
              ".MuiSvgIcon-root ": {
                fill: "#049c77 !important",
              },
            }}
            labelStyle={{ color: "#fff" }}
            value={selectedViewModel}
            onChange={(e) => {
              setSelectedViewModel(e.target.value);
            }}
          >
            {Object.keys(labelsColors).map((model, i) => {
              return (
                <MenuItem key={i} value={model}>
                  {model}
                </MenuItem>
              );
            })}
          </Select>
          <div className="stones">
            {!showFilteredStones
              ? // stones.map((stone, i) => {
                //     return (
                //         <div style={styles.stoneButtonWrapper}>
                //             <Button
                //                 variant="contained"
                //                 style={styles.stoneButton}
                //                 onClick={() => {
                //                     console.log('clicked', stone);
                //                     const centroid = stone['centroid'].map((x, i) => {
                //                         // if(i === 2)
                //                         //     return x;
                //                         // else return 512 - x;
                //                         return x;
                //                     });
                //                     console.log('centroid', centroid);
                //                     let axial = Math.round(centroid[2]);
                //                     let coronal = imgSize[1] - Math.round(centroid[1]);
                //                     let sagittal = Math.round(centroid[0]);
                //                     setStone(stone);
                //                     setSelectedImage({ axial, coronal, sagittal });
                //                 }}
                //             >

                //                 Stone {i + 1}
                //             </Button>
                //             <DeleteForeverIcon
                //                 style={styles.deleteStone}
                //                 onClick={() => {
                //                     const newStones = stones.filter((s, j) => j !== i);
                //                     // remove from scene
                //                     scene.forEach((scene_j, j) => {
                //                         if (scene_j.name === `stone_${stone['stone_id']}`) {
                //                             context.current.renderer.removeActor(scene_j.actor);
                //                             context.current.renderWindow.render();
                //                             // scene.splice(j, 1);
                //                             let newSceneList = scene.filter((s, k) => k !== j);
                //                             setScene(newSceneList);
                //                         }
                //                     });
                //                     setStones(newStones);
                //                     setStone(null);
                //                 }}
                //             />
                //         </div>
                //     );
                // })

                selectedViewModel in labelsColors && selectedViewModel in labels
                ? Object.entries(labels[selectedViewModel]).map(
                    (labelAndVal, index) => {
                      const label = labelAndVal[1];
                      const val = labelAndVal[0];
                      return (
                        <div
                          style={styles.labelOption(
                            selectedViewModel === "stone"
                          )}
                          onClick={() => {
                            if (selectedViewModel === "stone") {
                              const centroid = stones[index]["centroid"].map(
                                (x, i) => {
                                  // if(i === 2)
                                  //     return x;
                                  // else return 512 - x;
                                  return x;
                                }
                              );
                              let axial = Math.round(centroid[2]);
                              let coronal =
                                imgSize[0] - Math.round(centroid[1]);
                              let sagittal = Math.round(centroid[0]);
                              setStone(stones[index]);
                              setSelectedImage({
                                axial,
                                coronal,
                                sagittal,
                              });
                            } else {
                              if (
                                editMaskOptions.erase ||
                                editMaskOptions.draw
                              ) {
                                setEditMaskOptions({
                                  ...editMaskOptions,
                                  selectedLabelVal: val,
                                });
                              }
                            }
                          }}
                        >
                          <h6
                            style={
                              labelsColors[selectedViewModel][label] &&
                              styles.labelOptionText(
                                colorArrayToHexString(
                                  labelsColors[selectedViewModel][label]
                                )
                              )
                            }
                          >
                            {/* <h6> */}
                            {label}
                          </h6>
                          <VisibilityEye
                            style={{ flexGrow: 1 }}
                            key={label}
                            name={label}
                            visible={
                              switchLabelsState[selectedViewModel][label]
                            }
                            onVisibilityChange={(label, val) => {
                              setSwitchLabelsState((prevState) => {
                                return {
                                  ...prevState,
                                  [selectedViewModel]: {
                                    ...prevState[selectedViewModel],
                                    [label]: val,
                                  },
                                };
                              });
                            }}
                          />
                          {labelsColors[selectedViewModel][label] && (
                            <ColorPicker
                              style={{ flexGrow: 1 }}
                              name={label + "Color"}
                              value={colorArrayToHexString(
                                labelsColors[selectedViewModel][label]
                              )}
                              hideTextfield={true}
                              deferred={true}
                              onChange={(color) => {
                                setLabelsColors((prevState) => {
                                  return {
                                    ...prevState,
                                    [selectedViewModel]: {
                                      ...prevState[selectedViewModel],
                                      [label]: color.rgb,
                                    },
                                  };
                                });
                              }}
                            />
                          )}
                          {selectedViewModel === "stone" ? (
                            <DeleteForeverIcon
                              style={styles.deleteStone}
                              onClick={() => {
                                // const newStones = stones.filter((s, j) => j !== index);
                                const newStones = [];
                                const oldStoneIdToNewStoneId = {};
                                for (let i = 0; i < stones.length; i++) {
                                  if (i !== index) {
                                    newStones.push({ ...stones[i] });
                                    newStones[newStones.length - 1][
                                      "stone_id"
                                    ] = newStones.length;
                                    oldStoneIdToNewStoneId[
                                      stones[i]["stone_id"]
                                    ] = newStones.length;
                                  }
                                }

                                // remove from scene
                                let newSceneList = [];
                                // scene.forEach((scene_j, j) => {
                                for (let j = 0; j < scene.length; j++) {
                                  let scene_j = scene[j];
                                  if (
                                    scene_j.name ===
                                    `stone_${stones[index]["stone_id"]}`
                                  ) {
                                    context.current.renderer.removeActor(
                                      scene_j.actor
                                    );

                                    // scene.splice(j, 1);
                                    // let newSceneList = scene.filter((s, k) => k !== j);
                                    // setScene(newSceneList);
                                  } else {
                                    // change name of stone
                                    if (scene_j.name.startsWith("stone")) {
                                      scene_j.name = `stone_${
                                        oldStoneIdToNewStoneId[
                                          scene_j.name.split("_")[1]
                                        ]
                                      }`;
                                    }
                                    newSceneList.push(scene_j);
                                  }
                                }
                                setScene(newSceneList);
                                context.current.renderWindow.render();

                                setStones(newStones);
                                setStone(null);
                                let stoneLabels = [];
                                for (
                                  let i = 0;
                                  i < Object.values(newStones).length;
                                  i++
                                ) {
                                  stoneLabels.push("Stone " + (i + 1));
                                }
                                let labels2 = { ...labels };
                                labels2["stone"] = stoneLabels;
                                setLabels(labels2);
                                setLabels((prevState) => {
                                  return {
                                    ...prevState,
                                    stone: stoneLabels,
                                  };
                                });
                              }}
                            />
                          ) : null}
                        </div>
                      );
                    }
                  )
                : null
              : // null
                filteredStones.map((stone, i) => {
                  return (
                    <div style={styles.stoneButtonWrapper}>
                      <Button
                        variant="contained"
                        style={styles.stoneButton}
                        onClick={() => {
                          const centroid = stone["centroid"].map((x, i) => {
                            return x;
                          });
                          let axial = Math.round(centroid[2]);
                          let coronal = imgSize[0] - Math.round(centroid[1]);
                          let sagittal = Math.round(centroid[0]);
                          setStone(stone);
                          setSelectedImage({ axial, coronal, sagittal });
                        }}
                      >
                        Stone {i + 1}
                      </Button>
                      {stone.added ? (
                        <DeleteForeverIcon
                          style={styles.deleteStone}
                          onClick={() => {
                            const newStones = stones.filter((s, j) => j !== i);
                            // remove from scene
                            scene.forEach((scene_j, j) => {
                              if (
                                scene_j.name === `stone_${stone["stone_id"]}`
                              ) {
                                context.current.renderer.removeActor(
                                  scene_j.actor
                                );
                                context.current.renderWindow.render();
                                // scene.splice(j, 1);
                                let newSceneList = scene.filter(
                                  (s, k) => k !== j
                                );
                                setScene(newSceneList);
                              }
                            });
                            setStones(newStones);
                            setStone(null);
                            setFilteredStones(
                              filteredStones.map((s, j) => {
                                if (j === i) s.added = false;
                                return s;
                              })
                            );
                          }}
                        />
                      ) : (
                        <AddCircleIcon
                          style={styles.addStone}
                          onClick={() => {
                            const centroid = stone["centroid"].map((x, i) => {
                              return x;
                            });
                            let axial = Math.round(centroid[2]);
                            let coronal = imgSize[0] - Math.round(centroid[1]);
                            let sagittal = Math.round(centroid[0]);

                            handleAddStone(sagittal, coronal, axial);
                            setFilteredStones(
                              filteredStones.map((s, j) => {
                                if (j === i) s.added = true;
                                return s;
                              })
                            );
                            setShowFilteredStones(false);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
            <div>
              <IOSSwitch
                sx={{ margin: "7px" }}
                checked={showFilteredStones}
                onChange={() => setShowFilteredStones(!showFilteredStones)}
              />
              Show filtered stones
            </div>
            Add Stone:{" "}
            <IOSSwitch
              sx={{ margin: "7px" }}
              checked={addStone}
              onChange={() => {
                setAddStone(!addStone);
                setStone(null);
                setBboxPoints([]);
              }}
            >
              Add Stone
            </IOSSwitch>
            {addStone ? (
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <RadioGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  aria-label="sequence"
                  name="sequence"
                  value={addStoneMethod}
                  onChange={(e) => {
                    // setSeq(e.target.value);
                    // setSelectedImage({ ...selectedImage, [e.target.value]: 1 });
                    setAddStoneMethod(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={"Point"}
                    control={
                      <Radio
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "aquamarine",
                          },
                        }}
                      />
                    }
                    label={"Point"}
                    labelPlacement="top"
                    style={{ width: "30px" }}
                  />
                  <FormControlLabel
                    value={"Bbox"}
                    control={
                      <Radio
                        sx={{
                          color: "#fff",
                          "&.Mui-checked": {
                            color: "aquamarine",
                          },
                        }}
                      />
                    }
                    label={"Bbox"}
                    labelPlacement="top"
                    style={{ width: "30px" }}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
            {addStone && addStoneMethod === "Bbox" ? (
              <Button
                variant="filled"
                style={styles.addStoneButton}
                onClick={() => {
                  setAddStoneError("");
                  // check if bboxPoints are valid
                  if (
                    bboxPoints["axial"] === undefined ||
                    bboxPoints["coronal"] === undefined ||
                    bboxPoints["sagittal"] === undefined ||
                    bboxPoints["axial"].length !== 2 ||
                    bboxPoints["coronal"].length !== 2 ||
                    bboxPoints["sagittal"].length !== 2
                  ) {
                    setAddStoneError("Please select 2 points in each plane");
                    return;
                  }
                  handleAddStone(
                    selectedImage.sagittal,
                    selectedImage.coronal,
                    selectedImage.axial,
                    bboxPoints
                  );
                  setBboxPoints([]);
                  setStone(null);
                }}
              >
                Done &#10003;
              </Button>
            ) : null}
            {addStoneError ? (
              <div style={styles.errorText}>{addStoneError}</div>
            ) : null}
          </div>
          {stone ? (
            !showFilteredStones ? (
              <div style={styles.selectedStone}>
                <div style={styles.selectedStoneTextWrapper}>
                  <div style={styles.stoneTextHeader}>Side: </div>
                  <div>{stone["side"]}</div>
                </div>
                <div style={styles.selectedStoneTextWrapper}>
                  <div style={styles.stoneTextHeader}>Size: </div>
                  <div>{stone["size"]}</div>
                </div>
                <div style={styles.selectedStoneTextWrapper}>
                  <div style={styles.stoneTextHeader}>Location: </div>
                  <div>{stone["location"]}</div>
                </div>
                <div style={styles.selectedStoneTextWrapper}>
                  <div style={styles.stoneTextHeader}>Volume: </div>
                  <div>{stone["volume"]}</div>
                </div>
                <div style={styles.selectedStoneTextWrapper}>
                  <div style={styles.stoneTextHeader}>HU: </div>
                  <div>{stone["hu_value"]}</div>
                </div>
                <Button
                  variant="contained"
                  style={styles.editStoneButton}
                  onClick={() => {
                    setStoneIndex(stones.indexOf(stone));
                    setStoneModalOpen(true);
                  }}
                >
                  Edit
                </Button>
              </div>
            ) : (
              <div style={styles.selectedStone}>
                <div style={styles.selectedStoneTextWrapper}>
                  {stone.filter_text || stone.filter_reason}
                </div>
              </div>
            )
          ) : null}
          {addStone && addStoneMethod === "Point" && (
            <div style={styles.addStone}>
              <p>
                To add a new stone, go to any slice and then click on the centre
                of the stone you want to add.
              </p>
            </div>
          )}
          {addStone && addStoneMethod === "Bbox" && (
            <div style={styles.addStone}>
              <p>
                To add a new stone, select top left and bottom right corners of
                the bounding box in each plane.
              </p>
            </div>
          )}
          {(saving || addingStone) && (
            <CircularProgress style={styles.progress} />
          )}
          {editMaskOptions.erase ||
          editMaskOptions.draw ||
          editMaskOptions.drawPolygon ? (
            // edit draw size
            <div>
              {editMaskOptions.erase || editMaskOptions.draw ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  Size:{" "}
                  <input
                    type="range"
                    min="10"
                    max="100"
                    value={editMaskOptions.drawSize}
                    onChange={(e) => {
                      setEditMaskOptions({
                        ...editMaskOptions,
                        drawSize: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : null}
              {editMaskOptions.drawPolygon && (
                <p>
                  To draw polygon, select the points with left mouse button,
                  once done right click to fill inside polygon
                </p>
              )}
              {editMaskOptions.draw && (
                <p>
                  First click on the label to draw. Click left mouse button and
                  then drag mouse to draw. To stop drawing, click the right
                  mouse button
                </p>
              )}
              {editMaskOptions.erase && (
                <p>
                  To erase, first click and then drag mouse to erase. To stop
                  erasing, click the right mouse button
                </p>
              )}
              <Button
                variant="contained"
                style={styles.horseShoeButton}
                onClick={() => {
                  // first select file from file system by opening dialog
                  let url = `${baseURLAPI}/run_with`;
                  runAgain(url, false, "postprocessed2", true);
                }}
                // disabled={!loaded}
              >
                Rerun with Edited
              </Button>
            </div>
          ) : null}
        </div>

        {stone != null && (
          <Modal
            open={stoneModalOpen}
            onClose={() => {
              setStoneModalOpen(false);
            }}
          >
            <div style={styles.modal}>
              <div style={styles.modalHeader}>
                <h2 style={styles.modalHeaderText}>Add Stone</h2>
                <IconButton
                  onClick={() => {
                    setStoneModalOpen(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={styles.modalBody}>
                <div style={styles.modalBodyRow}>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>Side:</h1>
                    <Select
                      style={{ width: "100%", margin: "10px" }}
                      value={stone["side"]}
                      onChange={(e) => {
                        updateStone(e.target.value, "side");
                      }}
                    >
                      <MenuItem value={"LeftKidney"}>LeftKidney</MenuItem>
                      <MenuItem value={"RightKidney"}>RightKidney</MenuItem>
                      <MenuItem value={"LeftUreter"}>LeftUreter</MenuItem>
                      <MenuItem value={"RightUreter"}>RightUreter</MenuItem>
                      <MenuItem value={"Bladder"}>Bladder</MenuItem>
                    </Select>
                  </div>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>Size:</h1>
                    <EditableText
                      value={stone["size"]}
                      onChange={(value) => {
                        updateStone(value, "size");
                      }}
                    />
                  </div>
                </div>
                <div style={styles.modalBodyRow}>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>HU Value:</h1>
                    <EditableText
                      value={stone["hu_value"]}
                      onChange={(value) => {
                        updateStone(value, "hu_value");
                      }}
                    />
                  </div>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>Location:</h1>
                    <Select
                      style={{ width: "100%", margin: "10px" }}
                      value={stone["location"]}
                      onChange={(e) => {
                        updateStone(e.target.value, "location");
                      }}
                    >
                      <MenuItem value={"upper calyx"}>UpperCalyx</MenuItem>
                      <MenuItem value={"middle calyx"}>MiddleCalyx</MenuItem>
                      <MenuItem value={"lower calyx"}>LowerCalyx</MenuItem>
                      <MenuItem value={"upper ureter"}>UpperUreter</MenuItem>
                      <MenuItem value={"middle ureter"}>MiddleUreter</MenuItem>
                      <MenuItem value={"lower ureter"}>LowerUreter</MenuItem>
                      <MenuItem value={"UPJ"}>UPJ</MenuItem>
                      <MenuItem value={"UVJ"}>UVJ</MenuItem>
                      <MenuItem value={"renal pelvis"}>RenalPelvis</MenuItem>
                      <MenuItem value={"Bladder"}>Bladder</MenuItem>
                    </Select>
                  </div>
                </div>
                <div style={styles.modalBodyRow}>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>A/P:</h1>
                    <EditableText
                      value={stone["ant_pos"]}
                      onChange={(value) => {
                        updateStone(value, "ant_pos");
                      }}
                    />
                  </div>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>Distance:</h1>
                    <EditableText
                      value={stone["distance"]}
                      onChange={(value) => {
                        updateStone(value, "distance");
                      }}
                    />
                  </div>
                  <div style={styles.modalBodyRowItem}>
                    <h1 style={styles.modalBodyRowItemText}>Num of stones:</h1>
                    <EditableText
                      value={stone["num_stones"]}
                      onChange={(value) => {
                        updateStone(value, "num_stones");
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={styles.modalFooter}></div>
            </div>
          </Modal>
        )}
        <Popper
          open={popperOpen}
          anchorEl={popperAnchor}
          placement="bottom"
          style={{ zIndex: 1000 }}
        >
          <div style={styles.popper}>
            <img
              width={200}
              height={200}
              ref={(node) => {
                popperImgRef = node;
              }}
              style={{ borderRadius: "100px" }}
            />
          </div>
        </Popper>
        {savedConfig && (
          <Modal
            open={savedConfig}
            onClose={() => {
              setSavedConfig(false);
            }}
          >
            <div style={styles.saveModal}>{saveStatus}</div>
          </Modal>
        )}
      </div>
      <Modal
        open={MaskModalOpen}
        onClose={() => {
          setMaskModalOpen(false);
        }}
      >
        <div style={styles.fovModal}>
          <Box sx={{ width: "90%", paddingX: 3 }}>
            <Typography id="input-slidery" gutterBottom>
              {!preprocessedMask
                ? "Upload preprocessed organ mask file"
                : preprocessedMask.name}
            </Typography>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setpreprocessedMask(e.target.files[0])}
              />
            </Button>
          </Box>
          <Box sx={{ width: "90%", paddingX: 3 }}>
            <Typography id="input-sliderz" gutterBottom>
              {!stoneMask ? "Upload stone mask file" : stoneMask.name}
            </Typography>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => setstoneMask(e.target.files[0])}
              />
            </Button>
          </Box>
          <Box sx={{ width: "90%", padding: 3 }}>
            <Button
              variant="contained"
              onClick={() => {
                let url = `${baseURLAPI}/run_with_both`;
                runAgain_both(url, preprocessedMask, stoneMask);
                setMaskModalOpen(false);
              }}
            >
              Run with Multi Masks
            </Button>
          </Box>
        </div>
      </Modal>
      <Modal
        open={FovModalOpen}
        onClose={() => {
          setFovModalOpen(false);
        }}
      >
        <div style={styles.fovModal}>
          <Box sx={{ width: "90%", paddingX: 3 }}>
            <h3>Select FOV</h3>
            <Typography id="input-sliderx" gutterBottom>
              X min - {xvalue[0]} and X max - {xvalue[1]}
            </Typography>
            <Slider
              getAriaLabel={() => "input-sliderx"}
              value={xvalue}
              min={10}
              step={10}
              max={500}
              onChange={handleChangex}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
            />
          </Box>
          <Box sx={{ width: "90%", paddingX: 3 }}>
            <Typography id="input-slidery" gutterBottom>
              Y min - {yvalue[0]} and Y max - {yvalue[1]}
            </Typography>
            <Slider
              getAriaLabel={() => "input-slidery"}
              value={yvalue}
              min={10}
              step={10}
              max={500}
              onChange={handleChangey}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
            />
          </Box>
          <Box sx={{ width: "90%", paddingX: 3 }}>
            <Typography id="input-sliderz" gutterBottom>
              Z min - {zvalue[0]} and Z max - {zvalue[1]}
            </Typography>
            <Slider
              getAriaLabel={() => "input-sliderz"}
              value={zvalue}
              min={10}
              step={10}
              max={500}
              onChange={handleChangez}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              disableSwap
            />
          </Box>
          <Box sx={{ width: "90%", padding: 3 }}>
            <Button
              variant="contained"
              onClick={() => {
                let url = `${baseURLAPI}/run_diseased`;
                runAgain(url, false, "diseased");
                setFovModalOpen(false);
              }}
            >
              Run with Diseased
            </Button>
          </Box>
        </div>
      </Modal>
      <Modal
        open={reportModalOpen}
        onClose={() => {
          setReportModalOpen(false);
        }}
      >
        <div style={styles.reportModal(reportMode)}>
          <div style={styles.reportModalHeader}>
            <Select
              value={reportConfig}
              style={styles.configsSelect}
              onChange={(e) => {
                // setStones(e.target.value);
                // updateConfigs({ stone: e.target.value, i: e.target.key });
                setReportConfig(e.target.value);
              }}
            >
              {Object.keys(configs).map((configI, i) => {
                return (
                  <MenuItem key={i} value={configI}>
                    {configI}
                  </MenuItem>
                );
              })}
            </Select>
            <h2>Report ({reportConfig})</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                variant={reportMode === "view" ? "contained" : "outlined"}
                onClick={() => setReportMode("view")}
              >
                View
              </Button>
              <Button
                variant={reportMode === "edit" ? "contained" : "outlined"}
                onClick={() => setReportMode("edit")}
              >
                Edit
              </Button>
            </div>
          </div>
          {reportMode === "view" ? (
            typeof window !== "undefined" &&
            window.navigator &&
            window.navigator.mimeTypes &&
            window.navigator.mimeTypes["application/pdf"] ? (
              <iframe ref={pdfRef} style={{ width: "100%", height: "100%" }} />
            ) : pdfFile && !reportLoading ? (
              <div
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  maxHeight: "100%",
                  height: "100%",
                  overflowY: "scroll",
                }}
              >
                <Document
                  file={pdfFile}
                  onLoadSuccess={({ numPages }) => {
                    setPdfNumPages(numPages);
                    setPdfIndex(1);
                  }}
                >
                  <Page
                    pageNumber={pdfIndex}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={780}
                    height={400}
                  />
                </Document>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <p>
                    Page {pdfIndex} of {pdfNumPages}
                  </p>
                  <IconButton
                    onClick={() => {
                      if (pdfIndex > 1) setPdfIndex(pdfIndex - 1);
                    }}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (pdfIndex < pdfNumPages) setPdfIndex(pdfIndex + 1);
                    }}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <CircularProgress />
            )
          ) : (
            // :
            // <iframe ref={pdfRef} style={{ width: '100%', height: '100%' }} />

            <Report config={reportConfig} style={{ width: "100%", height: "80%" }} />
          )}
        </div>
      </Modal>
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
      >
        <div style={styles.reportConfigModal}>
          <table className="table table-bordered" style={{ width: "100%" }}>
            <tr>
              <th style={styles.reportTableHeaderItem}>Config</th>
              <th style={styles.reportTableHeaderItem}>Actions</th>
              <th style={styles.reportTableHeaderItem}>Sent</th>
              <th style={styles.reportTableHeaderItem}>Config Time</th>
            </tr>
            {reportConfigs.map((config, index) => (
              <tr key={index}>
                <td style={styles.reportTableBodyItem}>{config.config}</td>
                <td style={styles.reportTableBodyItem} className="rptModalAct">
                  <SaveIcon
                    style={{
                      fill: "#1b4b84",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    onClick={() => {
                      const token = admin
                        ? localStorage.getItem(`token-${center}`)
                        : localStorage.getItem("token");
                      axios({
                        method: "GET",
                        url: `${baseURL}/report/${jobId}/${config.config}`,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                        responseType: "arraybuffer",
                      })
                        .then((res) => {
                          // download file
                          console.log("res", res);
                          let blob = new Blob([res.data], {
                            type: "application/pdf",
                          });
                          let url = window.URL.createObjectURL(blob);
                          let link = document.createElement("a");
                          link.href = url;
                          link.download = `${jobId}_${config.config}.pdf`;
                          link.click();
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  />
                  <Link to={`/report/${jobId}`}>
                    <EditIcon
                      style={{
                        fill: "#1b4b84",
                        cursor: "pointer",
                        padding: 0,
                      }}
                    />
                  </Link>
                  <DeleteForeverIcon
                    style={{
                      fill: "red",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    onClick={(e) => {
                      const token = admin
                        ? localStorage.getItem(`token-${center}`)
                        : localStorage.getItem("token");
                      console.log("token", token);
                      const data = {
                        job_id: jobId,
                        config_name: config.config,
                      };
                      axios
                        .post(baseURLAPI + "/config", data, {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        })
                        .then((res) => {
                          console.log(res);
                          setSaveStatus("Deleted config successfully");
                          setSavedConfig(true);
                          setTimeout(() => {
                            setSaveStatus("");
                            setSavedConfig(false);
                          }, 1500);
                          getConfigs(jobId, center);
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }}
                  />
                  {!config.sent_to_pacs ? (
                    <SendIcon
                      style={{
                        fill: "#1b4b84",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      onClick={(e) => {
                        const token = admin
                          ? localStorage.getItem(`token-${center}`)
                          : localStorage.getItem("token");
                        const data = {
                          job_id: jobId,
                          config_name: config.config,
                        };
                        axios
                          .post(baseURLAPI + "/send_report", data, {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          .then((res) => {
                            console.log(res);
                            setSaveStatus("Report sent");
                            setSavedConfig(true);
                            setTimeout(() => {
                              setSaveStatus("");
                              setSavedConfig(false);
                            }, 1500);
                            getConfigs(jobId, center);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                  ) : (
                    <CancelScheduleSendIcon
                      style={{
                        fill: "#1b4b84",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      onClick={(e) => {
                        const token = admin
                          ? localStorage.getItem(`token-${center}`)
                          : localStorage.getItem("token");
                        const data = {
                          job_id: jobId,
                          config_name: config.config,
                        };
                        axios
                          .post(baseURLAPI + "/unsend_report", data, {
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          })
                          .then((res) => {
                            setSaveStatus("Report unsent");
                            setSavedConfig(true);
                            setTimeout(() => {
                              setSaveStatus("");
                              setSavedConfig(false);
                            }, 1500);
                            console.log(res);
                            getConfigs(jobId, center);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                  )}
                </td>
                <td style={styles.reportTableBodyItem}>
                  {config.sent_to_pacs ? (
                    <DoneIcon style={{ fill: "green", padding: 0 }} />
                  ) : (
                    <ClearIcon style={{ fill: "red", padding: 0 }} />
                  )}
                </td>
                <td style={styles.reportTableBodyItem}>{config.config_time}</td>
              </tr>
            ))}
          </table>
        </div>
      </Modal>
      <Modal
        open={feedbackModalOpen}
        onClose={() => {
          setFeedbackModalOpen(false);
          setNewFeedback("");
        }}
      >
        <div style={styles.feedbackModal}>
          Feedbacks
          {feedbacks.length > 0 ? (
            <div style={{ width: "100%", height: "70%" }}>
              {feedbacks.length} feedbacks found
              <div className="feedbacks">
                {feedbacks.map((feedback, index) => {
                  return (
                    <Feedback
                      key={index}
                      index={index + 1}
                      feedback={feedback.feedback}
                      feedbackBy={feedback.feedbackBy}
                      feedbackDate={feedback.feedbackDate}
                    />
                  );
                })}
              </div>
            </div>
          ) : (
            <p>No feedbacks found</p>
          )}
          <Select
            closeMenuOnSelect={false}
            style={{
              width: "200px",
              marginLeft: "10px",
              marginRight: "10px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            // components={animatedComponents}
            value={feedbackSelected}
            renderValue={(selected) => {
              console.log(selected);
              if (selected.length === 0) {
                return <em>Select feedback</em>;
              }
              return selected.join(", ");
            }}
            multiple
            options={feedbackValues}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 48 * 4.5 + 8,
                  width: 1000,
                },
              },
            }}
          >
            <MenuItem value="all" sx={{ display: "inline-flex", width: "33%" }}>
              <ListItemIcon>
                <Checkbox
                  checked={feedbackValues.length === feedbackSelected.length}
                  onChange={(e) => {
                    console.log(e, "clicked icon");
                    e.stopPropagation();
                    if (feedbackSelected.length === feedbackValues.length) {
                      // remove all from selected
                      setfeedbackSelected([]);
                    } else {
                      setfeedbackSelected(feedbackValues);
                    }
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="all" />
            </MenuItem>
            {feedbackValues.map((center, index) => {
              return (
                <MenuItem
                  key={index}
                  value={center}
                  sx={{ display: "inline-flex", width: "33%" }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        feedbackSelected.indexOf(center) > -1 ||
                        feedbackSelected.indexOf("all") > -1
                      }
                      onChange={(e) => {
                        console.log(e, "clicked icon");
                        e.stopPropagation();
                        if (
                          feedbackSelected.length ===
                            Object.keys(apiURLs).length ||
                          feedbackSelected.indexOf("all") > -1
                        ) {
                          // remove all from selected
                          let newfeedbackSelected = feedbackValues.filter(
                            (c) => c !== center
                          );
                          setfeedbackSelected(newfeedbackSelected);
                        } else {
                          // check if center is already selected
                          if (feedbackSelected.indexOf(center) > -1) {
                            let newfeedbackSelected = feedbackSelected.filter(
                              (c) => c !== center
                            );
                            setfeedbackSelected(newfeedbackSelected);
                          } // add center to selected
                          else
                            setfeedbackSelected([...feedbackSelected, center]);
                        }
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={center} />
                </MenuItem>
              );
            })}
          </Select>
          {/* <Select
                style={styles.select}
                value={newFeedback}
                onChange={(e) => setNewFeedback(e.target.value)}
              >
                {[
                  "Cyst",
                  "Mass",
                  "Bladder wall thickening",
                  "Single kidney",
                  "Horseshoe kidney",
                  "Ectopic kidney",
                  "Extra renal pelvis",
                  "Severe HUN ",
                  "Prostate calculus",
                  "Urethral calculus",
                  "Peripheral calculus",
                  "Renal artery calcifications",
                  "Phleboliths",
                  "Cystic calcification",
                  "Malrotated kidney",
                  "Duplex Ureter",
                  "Diverticulum",
                  "Staghorn calculus",
                  "FN in kidney",
                  "FN in ureter ",
                  "FN in bladder",
                  "FP in kidney",
                  "FP in ureter",
                  "FP in bladder",
                  "More than 2 kidneys",
                  "No bladder",
                  "bowel artifact",
                  "beam hardening artifact/streak artifact",
                  "separate calculi clubbed as 1 calculus due to region growing",
                  "poor kub mask",
                  "incorrect location",
                ].map((key, index) => (
                  <MenuItem key={index} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select> */}
          <TextField
            label="feedback"
            value={feedbackSelected.join(",")}
            onChange={(e) => setfeedbackSelected(e.target.value.split(","))}
          ></TextField>
          <Button
            onClick={() => {
              const token = admin
                ? localStorage.getItem(`token-${center}`)
                : localStorage.getItem("token");
              axios
                .post(
                  `${baseURLAPI}/feedback`,
                  {
                    job_id: jobId,
                    feedback: feedbackSelected.join(","),
                    // email: props.currentUser.email,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  console.log("res", res);
                  axios
                    .get(`${baseURLAPI}/feedback?job_id=${jobId}`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })
                    .then((res) => {
                      console.log("feedbacks", res);
                      setFeedbacks(res.data.feedback);
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }}
          >
            Add
          </Button>
        </div>
      </Modal>
      <Modal
        open={logModalOpen}
        onClose={() => setLogModalOpen(false)}
      >
        <div style={styles.logModal}>
          <pre style={styles.logContent}>{logText}</pre>
        </div>
      </Modal>
    </div>
  );
}
